import LocalStorageService from 'utils/LocalStorageService';
import { combineReducers } from 'redux';
import { createReducer } from 'deox';
import { loginAction, logoutAction } from './session.actions';
import { ErrorResponse } from '../../utils/toast-utils';

export const defaultErrorState: ErrorResponse = {};
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(loginAction.complete, () => defaultErrorState),
    handleAction(loginAction.error, (_, { payload }) => payload),
    handleAction(logoutAction.complete, () => defaultErrorState),
]);

export const defaultUsernameState: string | null = LocalStorageService.getUsername();
export const usernameReducer = createReducer(defaultUsernameState, handleAction => [
    handleAction(loginAction.complete, (_, { payload }) => payload.username),
    handleAction(loginAction.error, () => null),
    handleAction(logoutAction.complete, () => null),
]);

export const defaultTokenState: string | null = LocalStorageService.getToken();
export const tokenReducer = createReducer(defaultTokenState, handleAction => [
    handleAction(loginAction.complete, (_, { payload }) => payload.token),
    handleAction(loginAction.error, () => null),
    handleAction(logoutAction.complete, () => null),
]);

export const defaultExpiresState: Date | null = LocalStorageService.getExpiryDate();
export const expiresReducer = createReducer(defaultExpiresState, handleAction => [
    handleAction(loginAction.complete, (_, { payload }) => payload.expiryDate),
    handleAction(loginAction.error, () => null),
    handleAction(logoutAction.complete, () => null),
]);

export const sessionReducer = combineReducers({
    error: errorReducer,
    username: usernameReducer,
    token: tokenReducer,
    expiryDate: expiresReducer,
});

export type ISessionStore = ReturnType<typeof sessionReducer>;
