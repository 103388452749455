import axios, { AxiosRequestConfig } from 'axios';
import Config from 'config/config';
import LocalStorageService from '../LocalStorageService';

const APIClient = () => {
    const defaultOptions: AxiosRequestConfig = {
        baseURL: Config.BASE_URL,
        method: 'get',
    };

    // Create instance
    const instance = axios.create(defaultOptions);

    // Set the Bearer token for any request
    instance.interceptors.request.use(config => {
        const token = LocalStorageService.getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });

    return instance;
};

export default APIClient();
