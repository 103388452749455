import React from 'react';
import styled from 'styled-components';
import { Colors, Gradients, Spacing } from 'config/styling.constants';
import Button from 'components/button';

export interface SectionBlockProps {
    readonly title?: string;
    readonly children?: any;
    readonly serviceId?: number;
}

const ApplySectionContainer = styled.section`
    margin-top: 0;

    padding: ${Spacing.md} ${Spacing.md} ${Spacing.xl};
    background-color: ${Colors.black};
    color: ${Colors.white};
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    background-image: ${Gradients.defaultBlue};
`;

const ApplySection: React.FC<SectionBlockProps> = ({ title, children, serviceId }) => {
    return (
        <ApplySectionContainer>
            <h2>{title}</h2>
            {children}
            <Button outline to={`/services/${serviceId}`} style={{ width: '40%' }}>
                Register Now
            </Button>
        </ApplySectionContainer>
    );
};
export default ApplySection;
