import { createReducer } from 'deox';
import { combineReducers } from 'redux';
import { fetchVehicleImage, fetchVehicleList, selectVehicleAction } from './vehicle-list.actions';
import { IVehicle } from 'types';
import { logoutAction } from 'redux/session/session.actions';

export const defaultVehiclesState: IVehicle[] = [];
export const vehiclesReducer = createReducer(defaultVehiclesState, handleAction => [
    handleAction(fetchVehicleList.complete, (_, { payload }) => payload),
    handleAction(fetchVehicleList.error, state => state),
    handleAction(logoutAction.complete, () => defaultVehiclesState), // reset for logout
]);

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(fetchVehicleList.complete, () => defaultErrorState),
    handleAction(fetchVehicleList.error, (_, { payload }) => payload),
]);

export const defaultIsFetchingState = false;
export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
    handleAction(fetchVehicleList.next, () => true),
    handleAction([fetchVehicleList.complete, fetchVehicleList.error], () => false),
]);

export const defaultSelectedVehicleState = null as IVehicle | null;
export const selectedVehicleReducer = createReducer(defaultSelectedVehicleState, handleAction => [
    handleAction(fetchVehicleList.complete, (_, { payload }): IVehicle | null =>
        payload.length > 0 ? payload[0] : null,
    ),
    handleAction(selectVehicleAction, (_, { payload }) => payload),
    handleAction(logoutAction.complete, () => defaultSelectedVehicleState), // reset for logout
]);

export type VehicleImageObject = {
    vin: string;
    image: string;
    view?: string;
};

export interface VehicleImageMapping {
    [key: string]: VehicleImageObject;
}

export const defaultImageStore: VehicleImageMapping = {};
export const vehicleImageReducer = createReducer(defaultImageStore, handleAction => [
    handleAction(fetchVehicleImage.complete, (state, { payload, meta }) => {
        const key = payload + '-' + meta.view;
        return {
            ...state,
            [key]: {
                vin: payload,
                image: meta.image,
                view: meta.view,
            },
        };
    }),
]);

export const vehicleListReducer = combineReducers({
    vehicles: vehiclesReducer,
    error: errorReducer,
    isFetching: isFetchingReducer,
    imagesStore: vehicleImageReducer,
    selectedVehicle: selectedVehicleReducer,
});

export type VehicleListState = ReturnType<typeof vehicleListReducer>;
