import React from 'react';
import PageLayout from 'components/page.layout';
import styled from 'styled-components';
import { Typography } from 'config/styling.constants';

const Text = styled.p`
    margin: 20px 0px 0px;
`;

const BoldText = styled.p`
    margin: 20px 0px 0px;
    font-weight: bold;
    font-style: italic;
`;

const Ul = styled.ul`
    list-style: none;
    padding: 0px;
`;
const Line = styled.hr`
    margin: 0px;
`;

const Li = styled.li`
    ::before {
        content: '– ';
    }
`;

const Title = styled.p`
    margin: 20px 0px;
    font-family: ${Typography.boldFontFamily};
    font-size: ${Typography.headingLevelTwoFontSize};
`;

const SubTitle = styled.p`
    margin: 20px 0px 0px;
    font-family: ${Typography.boldFontFamily};
    font-size: ${Typography.bodyTextFontSize};
`;

const Section = styled.div`
    padding: 85px 0px;
`;

export default function DataPrivacy() {
    return (
        <PageLayout title="LEGAL INFORMATION ON DATA PROTECTION">
            <Text>
                BMW Group understands that use of your information requires your trust. BMW Group is committed to the
                highest standards of data privacy and will only use your information for clearly described purposes and
                in accordance with your data protection rights.
            </Text>
            <Text>Last updated: 15 May 2023</Text>
            <Section>
                <Title>Who is responsible for data processing?</Title>
                <Text>
                    Bayerische Motoren Werke Aktiengesellschaft, Petuelring 130, 80788 Munich, with its registered
                    office in Munich, is entered in the commercial register of the Local Court [Amtsgericht] of Munich
                    under HRB 42243 (hereinafter referred to as “BMW”). The BMW Group Labs service (referred to as “the
                    service”) is a family of applications and services running in the vehicle, and integrations with
                    devices such as Apple and Samsung watches, the Amazon Alexa and Google Assistant (integrated
                    devices). BMW provides the service to you and is responsible for related data processing activities.
                </Text>
            </Section>
            <Line />
            <Section>
                <Title>What information may be collected about you and how is it used?</Title>
                <Text>
                    The service is designed to be a highly personalised mobility companion. In order to provide the
                    personalised features of the service, BMW collects, stores and processes your personal data in
                    compliance with applicable law.
                </Text>
                <Text>The data collected for the service are processed for the following purposes:</Text>
                <SubTitle>A. Contracting to use the service (Art. 6(1)(b) GDPR)</SubTitle>
                <Text>In order to use the service, the following data categories are processed:</Text>
                <Ul>
                    <Text>
                        <Li>account login (BMW ConnectedDrive / myBMW login)</Li>
                    </Text>
                    <Text>
                        <Li>contact data (last name, first name, address, email address, etc.)</Li>
                    </Text>
                </Ul>
                <SubTitle>Delete</SubTitle>
                <Text>
                    Your account login used to access your BMW Connected service is also your login for your other BMW
                    services. You can delete your account at any time. For details, please scroll down to the section on
                    “Your data privacy rights”
                </Text>
                <SubTitle>B. To provide the personalized features of the service (Art. 6(1)(b) GDPR)</SubTitle>
                <Text>
                    The service collects and processes the following data to provide the user’s personalised features of
                    the service:
                </Text>
                <Text style={{ fontStyle: 'italic' }}>Service data</Text>
                <Ul>
                    <Text>
                        <Li>user profile (preferences, app id, Top driver number, etc.)</Li>
                    </Text>
                    <Text>
                        <Li>user feedback (service rating, comment, problem description, etc)</Li>
                    </Text>
                    <Text>
                        <Li>messages (message, recipient contact information, etc.)</Li>
                    </Text>
                    <Text>
                        <Li>vehicle profile (vehicle identification number; VIN, licence plate, etc.)</Li>
                    </Text>
                    <Text>
                        <Li>anonymised service analytics (user count, message count, models, HMI version etc)</Li>
                    </Text>
                </Ul>
                <Text style={{ fontStyle: 'italic' }}>Vehicle data</Text>
                <Ul>
                    <Text>
                        <Li>vehicle id (links your specific vehicle to the service)</Li>
                    </Text>
                    <Text>
                        <Li>
                            A complete list of the features, a detailed description for each service and the data used
                            can be found at the specific service descriptions and data privacy statement.
                        </Li>
                    </Text>
                </Ul>
                <SubTitle>Delete</SubTitle>
                <Text>
                    You can delete your data at any time in the app. There are several ways to delete your data:
                </Text>
                <Ul>
                    <Text>
                        <Li>For some data types, you can delete the individual data; such as a single destination</Li>
                    </Text>
                    <Text>
                        <Li>
                            You can unlink your vehicle from the service or delete your account by contacting the
                            specific service email address or labs@bmwgroup.com
                        </Li>
                    </Text>
                    <Text>
                        <Li>
                            If you contact BMW with a request to have your data deleted – the service data will be
                            deleted
                        </Li>
                    </Text>
                </Ul>
                <SubTitle>Consent</SubTitle>
                <Text>
                    For specific data types, the service will prompt for your consent before collecting the data. If you
                    decline, the features which require that data will be disabled. Specific consent is requested for:
                </Text>
                <Ul>
                    <Text>
                        <Li>position and movement data (time, position, speed, etc.)</Li>
                    </Text>
                    <Text>
                        <Li>calendar events (event title, location, start / end times)</Li>
                    </Text>
                    <Text>
                        <Li>contacts (name, address, phone number)</Li>
                    </Text>
                    <Text>
                        <Li>receiving notifications</Li>
                    </Text>
                </Ul>
                <Text>
                    You consent to allow the service to collect and process all other described data types when you
                    accept this privacy notice.
                </Text>
                <SubTitle>BMW Group Login</SubTitle>
                <Text>
                    To fully use the BMW Group Labs service, you must register in the ConnectedDrive portal. By
                    registering, you will receive an online customer account that also gives you access to additional
                    portals of the BMW Group.
                </Text>
                <SubTitle>C. Assurance of product quality and development of new products (Art. 6(1)(f) GDPR)</SubTitle>
                <Text>
                    Beyond the mere provision of services, the data collected under Section B. are also processed for
                    the purposes of quality assurance and for the development of new products and services by BMW. These
                    processing activities serve the legitimate interests of BMW in our effort to bring you the highest
                    quality products and services as well as new and innovative offerings.
                </Text>
                <Text>
                    In order to protect your privacy, the data used to improve our products and create new services is
                    stored in a form that it is not directly traceable to you or your vehicle.
                </Text>
                <Text>
                    The health of the service is constantly monitored. In the event that the monitoring detects critical
                    system fault some data collected under Section B. may be reviewed be to help determine the cause in
                    order to correct the problem. Access to this information is strictly limited and is only used when
                    needed to resolve a critical issue.
                </Text>
                <SubTitle>D. Consent-based customer care (Art. 6(1)(a) GDPR)</SubTitle>
                <Text>
                    If you have separately given your consent to the further use of your personal data for marketing
                    purposes and/or market research your personal data will be used for this purpose.
                </Text>
                <SubTitle>
                    E. Compliance with legal obligations to which BMW is subject (Art. 13(1)(c), Art. 6(1)(c) GDPR)
                </SubTitle>
                <Text>
                    BMW will also process personal data if there is a legal obligation to do so. This may be the case if
                    we need to contact you because your vehicle is affected by a recall or technical action.
                </Text>
                <Text>
                    ‎<br />
                    Collected data is also processed in the course of ensuring the operation of IT systems; such as:
                </Text>
                <Ul>
                    <Text>
                        <Li>backup and recovery of data processed in IT systems,</Li>
                    </Text>
                    <Text>
                        <Li>logging and monitoring of transactions to verify proper functioning of IT systems,</Li>
                    </Text>
                    <Text>
                        <Li>detection and prevention of unauthorised access to personal data,</Li>
                    </Text>
                    <Text>
                        <Li>Incident and problem management for troubleshooting IT systems.</Li>
                    </Text>
                </Ul>
                <Text>
                    <br />
                    Collected data is also processed in the context of internal compliance management, in which we
                    check, whether you have been sufficiently advised in the context of a contract and that the dealer
                    has complied with all legal obligations.
                </Text>
                <Text>
                    <br />
                    ‎BMW is subject to a variety of other legal obligations. In order to comply with these obligations,
                    we process your data to the extent required and, if necessary, pass these on to the responsible
                    authorities within the framework of statutory reporting obligations
                </Text>
                <SubTitle>F. Data transfer to selected third parties</SubTitle>
                <Text>
                    The service includes features that utilise selected third parties in order to provide the function
                    of the feature. In most cases, the data is provided in anonymised form such as the request to the
                    map provider to retrieve the map image for your location. In other cases, the feature may require
                    the transfer of your personal information to the third party such as when you schedule a service
                    appointment for your vehicle or send a message to a friend. Features that send your personal data to
                    a third party will inform you of the transmission or prompt for your consent before sending the
                    data.
                </Text>
                <Text>
                    Details for all third party transmissions are described in the respective service descriptions.
                </Text>
            </Section>
            <Line />
            <Section>
                <Title>How do we collect your personal data?</Title>
                <Text>BMW collects your personal information in the following conditions:</Text>
                <Ul>
                    <Text>
                        <Li>
                            the app is actively running, either in the foreground or background, on your mobile device
                        </Li>
                    </Text>
                    <Text>
                        <Li>You actively use the features of the app</Li>
                    </Text>
                    <Text>
                        <Li>
                            If you access the service via an integrated device the service will collect anonymised usage
                            analytics
                        </Li>
                    </Text>
                    <Text>
                        <Li>
                            When you use the service to perform ConnectedDrive functions such as executing a remote
                            command (e.g. locking your doors) or viewing your vehicle status the data is generated by
                            your vehicle. All ConnectedDrive data is collected and processed under your ConnectedDrive
                            agreement and is detailed under the ConnectedDrive privacy policy included separately in the
                            app.
                        </Li>
                    </Text>
                </Ul>
            </Section>
            <Line />
            <Section>
                <Title>How do we keep your information safe?</Title>
                <Text>
                    We secure your data using state-of-the-art technology. By way of example, the following security
                    measures are used to protect your personal data against misuse or any other form of unauthorised
                    processing:
                </Text>
                <Ul>
                    <Text>
                        <Li>
                            access to personal data is restricted to only a limited number of authorised persons for
                            specified purposes;
                        </Li>
                    </Text>
                    <Text>
                        <Li>data is transferred only in encrypted form;</Li>
                    </Text>
                    <Text>
                        <Li>data is stored in encrypted form;</Li>
                    </Text>
                    <Text>
                        <Li>
                            the IT systems used for the processing of the data are technically isolated from other
                            systems to prevent unathorised access, e.g. by hacking;
                        </Li>
                    </Text>
                    <Text>
                        <Li>
                            access to the systems is monitored permanently in order to detect and avert misuse at an
                            early stage.
                        </Li>
                    </Text>
                </Ul>
            </Section>
            <Line />
            <Section>
                <Title>How long do we store your data?</Title>
                <Text>
                    We only store your personal data for as long as is required for the respective purpose. If data is
                    processed for multiple purposes, they are automatically erased, or stored in a form that is not
                    directly traceable to you, as soon as the last specified purpose has been fulfilled.
                </Text>
                <Text>
                    The service retains the data for a varying period depending on the data type. The details for each
                    data type are described in the service descriptions. Retention periods fall into the following
                    categories:
                </Text>
                <Ul>
                    <Text>
                        <Li>
                            Data is retained only until the process, request or action is completed (example: when you
                            send a message to a friend to notify them of your estimated arrival time, the message is
                            sent and then deleted)
                        </Li>
                    </Text>
                    <Text>
                        <Li>
                            Data is retained for a defined period (example: vehicle data is retained for 30 days,
                            position data is retained for 6 months)
                        </Li>
                    </Text>
                    <Text>
                        <Li>
                            Account data is retained until it is deleted on request it or deleted due to a specific
                            specific period of inactivity (example: your user profile)
                        </Li>
                        <Li>
                            Service-related data is retained until the end of the service period. The service and the
                            data are deleted after the pilot service is completed (service usually last for 6-12 months)
                        </Li>
                    </Text>
                </Ul>
            </Section>
            <Line />
            <Section>
                <Title>Who will we share your data with and how will we protect it?</Title>
                <Text>
                    BMW is a global company. Your personal data is processed preferably within the EU by BMW employees,
                    national sales companies, authorised retailers and service providers engaged by us.
                </Text>
                <Text>
                    If data are processed in countries outside the EU, BMW ensures that your personal data are processed
                    in accordance with the European level of data protection, using EU standard agreements, including
                    suitable technical and organisational measures.
                </Text>
            </Section>
            <Line />
            <Section>
                <Title>How you see and change your privacy preferences</Title>
                <Text>
                    You can review and change your privacy settings at any time in the Connected Drive Portal. Depending
                    on the vehicle model, you can also access a privacy menu in your vehicle in which you can also
                    change the vehicle related settings.
                </Text>
            </Section>
            <Line />
            <Section>
                <Title>Your data privacy rights, your right to complain and contacting BMW</Title>
                <Text>
                    As BMW is processing your data, you are entitled to claim certain rights under GDPR and other
                    relevant data protection regulations. This section explains your rights under the GDPR.
                </Text>
                <SubTitle>Your rights</SubTitle>
                <Text>According to the GDPR you are entitled to the following rights:</Text>
                <BoldText>Right to withdraw Consent (Art. 7 GDPR):</BoldText>
                <Text>
                    If you wish to withdraw your consent allowing the service to process your data, in the app tap the
                    “Delete all application data” option in the “Privacy and Terms” menu and then delete the app from
                    your smartphone.
                </Text>
                <Text>
                    Settings to change your data collection permissions service data types are available in the app
                    under the “Settings” menu.
                </Text>
                <BoldText>Right to access your information (Art. 15 GDPR):</BoldText>
                <Text>
                    You can request information about the data that we hold about you at any time. This information
                    includes, but is not limited to, the categories of data we process, for which purposes we process
                    them, the source of the data if we have not collected it directly from you, and, if applicable, the
                    recipients to whom we have submitted your information. You will receive 1 free copy of your data. If
                    you are interested in additional copies, we reserve the right to charge for further copies.
                </Text>
                <Text>
                    The data collected and generated by the service can be viewed in either your ConnectedDrive portal
                    or the app itself. The data is shown in the app as part of the display for the feature that collects
                    or uses it. The number of destinations shown on the Destination tab are limited to make the feature
                    easier to use. All destinations stored in the service can be viewed under the menu option Privacy
                    and Terms | Learned Destinations.
                </Text>
                <Text>
                    You can also view your account information and vehicle data at any time in the ConnectedDrive
                    portal.
                </Text>
                <BoldText>Right to correction (Art. 16 GDPR):</BoldText>
                <Text>
                    You can request BMW to correct your data. We will take reasonable steps to keep the information we
                    hold about you and process on an ongoing basis, accurate, complete, current, and relevant, based on
                    the most up-to-date information available to us.
                </Text>
                <Text>
                    You can correct most of the data processed by the Connected service directly in the Connected Drive
                    smartphone app or on the ConnectedDrive portal.
                </Text>
                <BoldText>Right to deletion (Art. 17 GDPR):</BoldText>
                <Text>You can ask that your data be deleted, if the legal prerequisites exist.</Text>
                <Text>This may be the case under Art. 17 GDPR if:</Text>
                <Ul>
                    <Text>
                        <Li>
                            The data is no longer required for the purposes for which it was collected or otherwise
                            processed;
                        </Li>
                    </Text>
                    <Text>
                        <Li>
                            You revoke your consent, which is the basis of the data processing and there is no other
                            legal basis for processing;
                        </Li>
                    </Text>
                    <Text>
                        <Li>
                            You object to the processing of your data and there is no legitimate reason for the
                            processing, or object to data processing for direct marketing purposes;
                        </Li>
                    </Text>
                    <Text>
                        <Li>The data was processed unlawfully</Li>
                    </Text>
                </Ul>
                <Text>unless processing is required:</Text>
                <Ul>
                    <Text>
                        <Li>To ensure compliance with a legal obligation that requires us to process your data;</Li>
                    </Text>
                    <Text>
                        <Li>Especially with regard to statutory retention periods;</Li>
                    </Text>
                    <Text>
                        <Li>To assert, exercise or defend legal claims.</Li>
                    </Text>
                </Ul>
                <BoldText>Right to restriction of processing (Art. 18 GDPR):</BoldText>
                <Text>You may demand that we restrict the processing of your data if:</Text>
                <Ul>
                    <Text>
                        <Li>
                            You deny the accuracy of the data. Data will be restricted from further processing for the
                            time period we need to verify the accuracy of the data;
                        </Li>
                    </Text>
                    <Text>
                        <Li>
                            The processing is unlawful and you refuse the deletion of your data. Instead you demand the
                            restriction of use;
                        </Li>
                    </Text>
                    <Text>
                        <Li>
                            We no longer need your information, but you need it to enforce, exercise or defend your
                            rights;
                        </Li>
                    </Text>
                    <Text>
                        <Li>
                            You have objected to the processing, as long as it is not certain that our legitimate
                            interest in processing of the data outweigh your personal rights.
                        </Li>
                    </Text>
                </Ul>
                <BoldText>Right to data portability (Art. 20 GDPR):</BoldText>
                <Text>
                    Upon your request, we will transfer your data - as far as is technically possible - to another
                    person in charge. However, you are only entitled to this right if the data processing is based on
                    your consent or is required to carry out a contract. Instead of receiving a copy of your data, you
                    may also ask us to submit the data directly to another person in charge who you specify.
                </Text>
                <BoldText>Right to objection (Art. 21 GDPR):</BoldText>
                <Text>
                    You may object to the processing of your data at any time for reasons that arise from your
                    particular situation, if the data processing is based on your consent or on our legitimate interests
                    or that of a third party. In this case, we will no longer process your data. The latter does not
                    apply if we can prove compelling legitimate reasons for processing that outweigh your interests or
                    we need your data to assert, exercise, or defend legal claims.
                </Text>
                <BoldText>Time limits for the fulfillment of data subject rights:</BoldText>
                <Text>
                    We make every effort to comply with all requests within 30 days. However, this period may be
                    extended for reasons relating to the specific right or complexity of your request.
                </Text>
                <BoldText>Restriction of information in the fulfillment of data subject rights:</BoldText>
                <Text>
                    In certain situations, we may be unable to provide you with information about all of your data due
                    to legal requirements. If we have to reject your request for information in such a case, we will
                    inform you at the time about the reasons of the refusal.
                </Text>
                <BoldText>Complaint to regulators:</BoldText>
                <Text>
                    BMW AG takes your concerns and rights very seriously. However, if you believe that we have not
                    adequately complied with your complaints or concerns, you have the right to lodge a complaint with a
                    competent data protection authority.
                </Text>
                <BoldText>Contact BMW</BoldText>
                <Text>To this end, please contact BMW Customer Service</Text>
                <Text>
                    By email: <a href={'mailto: kundenbetreuung@bmw.de'}>kundenbetreuung@bmw.de</a> or
                </Text>
                <Text>by calling the hotline: +49 89 1250 160 00 (Mon–Sun 8:00 am to 8:00 pm).</Text>
                <Text>You can also directly contact BMW’s data protection officer:</Text>
                <Text>
                    data protection officer
                    <br /> ‎BMW AG
                    <br /> ‎Petuelring 130
                    <br /> ‎80788 Munich
                    <br /> ‎<a href={'mailto: datenschutz@bmw.de'}>datenschutz@bmw.de</a>
                </Text>
            </Section>
        </PageLayout>
    );
}
