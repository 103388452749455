import React from 'react';
import ServiceGrid from './grid';
import PageLayout from 'components/page.layout';
import { Title } from 'components/styled-components';

const ServiceOverview = () => {
    return (
        <PageLayout paddingTop="2em">
            <Title>Services</Title>
            <ServiceGrid />
        </PageLayout>
    );
};
export default ServiceOverview;
