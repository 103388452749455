import React, { SyntheticEvent, useEffect } from 'react';
import styled from 'styled-components';
import { Colors, Spacing } from 'config/styling.constants';
import { MobileButton, NavAnchor, NavigationItem, NavigationLinks, NavLink } from './navigation-components';
import { getOAuthState, getOAuthUrl } from 'utils/login-utils';
import NavigationLogo from './logo';

interface NavigationItems {
    href: string;
    name: string;
}

interface MobileNavigationProps {
    isOpen: boolean;
    onClick?: () => void;
    navItems?: NavigationItems[];
    logout?: (e: SyntheticEvent) => void;
    isAuthenticated: boolean;
    oAuthState?: string;
    toggleTour?: () => void;
}

const Container = styled.aside<MobileNavigationProps>`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    will-change: transform, visibility;
    transform: ${props => (props.isOpen ? `translateX(0%)` : `translateX(100%)`)};
    transition: visibility 150ms linear, transform 250ms ease-in-out;
    background-color: ${Colors.lightGrey};
    width: 100%;
    z-index: 5;
    max-height: 100vh;
    @media (min-width: 865px) {
        display: none;
    }
`;

const Header = styled.header`
    margin-bottom: ${Spacing.lg};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: ${Colors.white};
    padding: ${Spacing.sm};
`;

const MobileNavigation: React.FC<MobileNavigationProps> = ({ isOpen, onClick, navItems, isAuthenticated, logout }) => {
    const [url, setUrl] = React.useState('');

    useEffect(() => {
        (async () => {
            setUrl(await getOAuthUrl(getOAuthState()));
        })();
    }, []);

    return (
        <Container isOpen={isOpen} isAuthenticated={isAuthenticated} className="mobile-navigation">
            <Header>
                <NavigationLogo href={'/'} />
                <MobileButton
                    onClick={onClick}
                    aria-haspopup="true"
                    aria-controls="mobile-nav"
                    aria-expanded="false"
                    close-nav
                >
                    &times; <span className="sr-only">Close menu</span>
                </MobileButton>
            </Header>
            <NavigationLinks>
                {navItems &&
                    navItems.map((item, index) => (
                        <NavigationItem key={index}>
                            <NavLink to={item.href} onClick={onClick}>
                                {item.name}
                            </NavLink>
                        </NavigationItem>
                    ))}
                {!isAuthenticated && url && (
                    <NavigationItem key={'login'}>
                        <NavAnchor href={url}>Login</NavAnchor>
                    </NavigationItem>
                )}
                {isAuthenticated && (
                    <NavigationItem key={'logout'}>
                        <NavLink to={'/'} onClick={logout}>
                            Logout
                        </NavLink>
                    </NavigationItem>
                )}
            </NavigationLinks>
        </Container>
    );
};

export default MobileNavigation;
