import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';
import { VehicleImageView } from 'types';

export class VehicleAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    getVehicleList() {
        return this.client.get(`/vehicles`);
    }

    getVehicleImage(vin: string, width: number, view: VehicleImageView = 'FRONTSIDE'): Promise<string | null> {
        let viewAngle: number;
        switch (view) {
            case 'REAR':
                viewAngle = 180;
                break;
            case 'SIDE':
                viewAngle = 90;
                break;
            case 'FRONTSIDE':
                viewAngle = 330;
                break;
            case 'FRONT':
            default:
                viewAngle = 0;
                break;
        }

        return this.client
            .get<string>(`/vehicles/${vin}/image?width=${width}&startAngle=${viewAngle}`)
            .then(response => response.data)
            .catch(() => null);
    }
}
export default new VehicleAPI(Client);
