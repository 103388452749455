import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import api from 'utils/api/vehicle.api';
import { IVehicle, VehicleImageView } from 'types';
import { loadServices } from 'redux/servicesOverview/services-overview.actions';

export const selectVehicleAction = createActionCreator('SELECT_VEHICLE', resolve => (vehicle: IVehicle) =>
    resolve(vehicle),
);

export const fetchVehicleList = Object.assign(_fetchVehicleListThunk, {
    next: createActionCreator('FETCH_VEHICLE_LIST_NEXT'),
    complete: createActionCreator('FETCH_VEHICLE_LIST_COMPLETE', resolve => (vehicles: IVehicle[]) =>
        resolve(vehicles),
    ),
    error: createActionCreator('FETCH_VEHICLE_LIST_ERROR', resolve => (error: string) => resolve(error)),
});

export const fetchVehicleImage = Object.assign(_fetchVehicleImageThunk, {
    next: createActionCreator('FETCH_VEHICLE_IMAGE_NEXT'),
    complete: createActionCreator(
        'FETCH_VEHICLE_IMAGE_COMPLETE',
        resolve => (vin: string, meta: VehicleImageMetaPayload) => resolve(vin, meta),
    ),
    error: createActionCreator('FETCH_VEHICLE_IMAGE_ERROR', resolve => (error: string) => resolve(error)),
});

function _fetchVehicleListThunk() {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        dispatch(fetchVehicleList.next());

        try {
            const response = await api.getVehicleList();

            if (response.status === 200) {
                dispatch(fetchVehicleList.complete(response.data));

                // pre fetch dropdown images
                response.data.forEach((vehicle: IVehicle) => {
                    dispatch(fetchVehicleImage(vehicle.vin, 100, 'SIDE'));
                });
                dispatch(loadServices());
            } else {
                dispatch(fetchVehicleList.error(JSON.stringify(response.data)));
            }
        } catch (error) {
            dispatch(fetchVehicleList.error('Error: ' + JSON.stringify(error)));
        }
    };
}

function _fetchVehicleImageThunk(vin: string, width: number, view?: VehicleImageView) {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        dispatch(fetchVehicleImage.next());

        try {
            const image = await api.getVehicleImage(vin, width, view);
            if (image != null) {
                const meta: VehicleImageMetaPayload = {
                    image,
                    width,
                    view,
                };
                dispatch(fetchVehicleImage.complete(vin, meta));
            } else {
                dispatch(fetchVehicleImage.error('Error: no image found'));
            }
        } catch (error) {
            dispatch(fetchVehicleImage.error('Error: ' + JSON.stringify(error)));
        }
    };
}

export type VehicleImageMetaPayload = {
    image: string;
    width: number;
    view?: VehicleImageView;
};
