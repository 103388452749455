import React, { ChangeEvent, Fragment } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
    IService,
    IVehicle,
    RootStore,
    ServiceSurveyQuestion,
    ServiceSurveyResponse,
    SubscribeActionPayload,
} from 'types';
import { selectServicesDetails } from 'redux/serviceDetails/service-details.selector';
import { loadServiceDetails, subscribeAction } from 'redux/serviceDetails/service-details.actions';
import { selectSelectedVehicle } from 'redux/vehicle-list/vehicle-list.selector';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import VehicleImage from 'components/vehicle-image';
import ServiceAPI from 'utils/api/services.api';
import PrivacyAndTermsModal from './privacy-and-terms.modal';
import { Col } from 'react-bootstrap';
import InputBox from 'components/inputBox';
import PageLayout from '../../../components/page.layout';
import ServiceUtils from '../../../utils/service.utils';
import { toastifyPromise } from '../../../utils/toast-utils';

const VehicleImageContainer = styled.div`
    width: 300px;
    min-height: 250px;
`;

type TParams = { id: string };

interface AcceptTermsPageProps extends RouteComponentProps<TParams> {
    selectedService?: IService;
    currentVehicle: IVehicle | null;
    loadServiceDetails: (serviceId: number) => void;
    subscribeAction: (serviceId: number, payload: SubscribeActionPayload) => void;
}

interface AcceptTermsPageState {
    serviceId: number;
    termsAccepted: boolean;
    privacyPolicyAccepted: boolean;
    termsAndConditions: string;
    dataPrivacy: string;
    surveyQuestions: ServiceSurveyQuestion[];
    surveyResponses: ServiceSurveyResponse[];
}

class AcceptTermsPage extends React.Component<AcceptTermsPageProps, AcceptTermsPageState> {
    constructor(props: AcceptTermsPageProps) {
        super(props);
        const serviceId = Number(this.props.match.params.id);

        this.state = {
            serviceId,
            termsAccepted: false,
            privacyPolicyAccepted: false,
            termsAndConditions: '',
            dataPrivacy: '',
            surveyQuestions: [],
            surveyResponses: [],
        };
        this.subscribeAction = this.subscribeAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSurveyChange = this.handleSurveyChange.bind(this);
    }

    componentDidMount() {
        if (!this.props.selectedService) {
            this.props.loadServiceDetails(this.state.serviceId);
        }

        toastifyPromise(ServiceAPI.getServiceSurvey(this.state.serviceId), 'Failed to get Service Survey').then(res =>
            this.setState({ surveyQuestions: res.data }),
        );

        toastifyPromise(
            ServiceAPI.getServicePolicy(this.state.serviceId),
            'Failed to load terms and data privacy statement',
        ).then((res: any) => {
            this.setState({
                termsAndConditions: res.data.termsAndConditions,
                dataPrivacy: res.data.dataPrivacy,
            });
        });
    }

    componentDidUpdate(prevProps: AcceptTermsPageProps) {
        // Reload service details if selected vehicle changed
        if (this.props.currentVehicle !== prevProps.currentVehicle) {
            this.setState({ termsAccepted: false, privacyPolicyAccepted: false });
        }
    }

    subscribeAction = async () => {
        await this.props.subscribeAction(this.state.serviceId, {});
        this.props.history.push(`/services/${this.state.serviceId}`);
        toastifyPromise(
            ServiceAPI.putServiceSurvey(
                this.state.serviceId,
                this.state.surveyResponses.filter(survey => survey.response.length > 0),
                this.props.currentVehicle ? this.props.currentVehicle.vin : '',
            ),
            'Failed to update Survey Responses',
            'Successfully subscribed to service',
        );
    };

    fillSurveyResponses = () => {
        const initialResponses: ServiceSurveyResponse[] = [];
        for (let i = 0; i < this.state.surveyQuestions.length; i++) {
            initialResponses.push({ response: '', questionId: 0 });
        }
        this.setState({ surveyResponses: initialResponses });
    };

    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.checked;
        switch (name) {
            case 'termsAccepted':
                this.setState({ termsAccepted: value });
                break;
            case 'privacyPolicyAccepted':
                this.setState({ privacyPolicyAccepted: value });
                break;
            default:
                break;
        }
    };

    handleSurveyChange = (e: ChangeEvent, index: number, questionId: number) => {
        const event = e as ChangeEvent<HTMLInputElement>;
        const value = event.target.value;
        const responses: ServiceSurveyResponse[] = this.state.surveyResponses;
        responses[index].questionId = questionId;
        responses[index].response = value;

        this.setState({ surveyResponses: responses });
    };

    render() {
        const service = this.props.selectedService || ServiceUtils.createEmptyService();
        const vehicle = this.props.currentVehicle || { model: '', vin: '' };
        if (this.state.surveyResponses.length === 0 && !(this.state.surveyQuestions.length === 0)) {
            this.fillSurveyResponses();
        }

        return (
            <Fragment>
                <PageLayout paddingTop="2em">
                    <h1>Terms and Conditions</h1>
                    <VehicleImageContainer>
                        <VehicleImage vin={vehicle.vin} imageWidth={400} view="FRONTSIDE" />
                    </VehicleImageContainer>
                    <Form.Row>
                        <Col sm={7}>
                            <h2>Service: {service.name}</h2>
                            <h2 style={{ marginBottom: '10px' }}>
                                Current vehicle: {vehicle.model} (VIN: {vehicle.vin})
                            </h2>
                            <Form.Row>
                                <Form.Group controlId="termsAccepted">
                                    <Form.Check
                                        onChange={this.handleChange}
                                        name="termsAccepted"
                                        type="checkbox"
                                        checked={this.state.termsAccepted}
                                        label="I have read & agree to the terms that are in English:"
                                    />
                                </Form.Group>
                                <PrivacyAndTermsModal
                                    content={this.state.termsAndConditions}
                                    title="Terms and Conditions"
                                    linkTitle="terms of service"
                                />
                            </Form.Row>
                            <Form.Row>
                                <Form.Group controlId="privacyPolicyAccepted">
                                    <Form.Check
                                        onChange={this.handleChange}
                                        name="privacyPolicyAccepted"
                                        type="checkbox"
                                        checked={this.state.privacyPolicyAccepted}
                                        label="I have read & agree to the privacy policy that are in English:"
                                    />
                                </Form.Group>
                                <PrivacyAndTermsModal
                                    content={this.state.dataPrivacy}
                                    title="Privacy Policy"
                                    linkTitle="privacy policy of service"
                                />
                            </Form.Row>
                        </Col>
                        <Col sm={5}>
                            {this.state.surveyQuestions.map((surveyElem, index) => (
                                <InputBox
                                    key={`surveyElem.${index}`}
                                    label={surveyElem.question}
                                    placeholder={surveyElem.required ? 'Required' : ''}
                                    handleChange={e => this.handleSurveyChange(e, index, surveyElem.id)}
                                    smallMarginTop={true}
                                />
                            ))}
                        </Col>
                    </Form.Row>
                    <Button
                        onClick={() => this.subscribeAction()}
                        disabled={
                            !(
                                this.state.privacyPolicyAccepted &&
                                this.state.termsAccepted &&
                                this.state.surveyResponses.every(
                                    (entry, index) =>
                                        !(entry.response === '' && this.state.surveyQuestions[index].required),
                                )
                            )
                        }
                    >
                        Accept and Subscribe
                    </Button>
                </PageLayout>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    selectedService: selectServicesDetails(state),
    currentVehicle: selectSelectedVehicle(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadServiceDetails: (serviceId: number) => dispatch(loadServiceDetails(serviceId)),
    subscribeAction: (serviceId: number, payload: SubscribeActionPayload) =>
        dispatch(subscribeAction(serviceId, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTermsPage);
