import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

export interface ErrorResponse {
    response?: AxiosResponse;
}

export function getErrorMessage(error: ErrorResponse): string {
    if (error && error.response) {
        const {
            response: { data, status },
        } = error;
        switch (status) {
            // connection refused, server not reachable
            case 0:
            case 504:
                return 'Server not reachable';
            case 404:
                return 'The requested resource could not be found';
            case 401:
                return 'Unauthorized';
            case 400:
                return data.message || 'Bad request';
            case 500:
                return 'Internal server error';
        }
    }
    return 'An unexpected error occurred';
}

export function toastifyPromise<T>(promise: Promise<T>, errorMessage: string, successMessage?: string): Promise<T> {
    return promise
        .then((result: any) => {
            if (result && result.isAxiosError) {
                throw result;
            }
            successMessage && toast.success(successMessage);
            return result;
        })
        .catch((error: any) => {
            const messageDetail = getErrorMessage(error);
            if (messageDetail) {
                toast.error(`${errorMessage}:\n${messageDetail}`);
            } else {
                toast.error(errorMessage);
            }
            return Promise.reject(error);
        });
}
