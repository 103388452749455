import styled from 'styled-components';
import { Colors, Spacing, Typography } from '../../config/styling.constants';
import { Link } from 'react-router-dom';

export const NavigationLinks = styled.ul`
    display: none;
    @media (min-width: 865px) {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        list-style-type: none;
        margin-top: 0;
        padding-left: 0;
    }
    .mobile-navigation & {
        display: inherit;
    }
`;
export const MobileNavigationLinks = styled.ul`
    display: flex;
    height: ${({ children }) => (children ? '40px' : '0')};
    @media (min-width: 865px) {
        display: none;
    }
`;

interface NavigationItemProps {
    marginRight?: string;
}

export const NavigationItem = styled.li<NavigationItemProps>`
    display: inline-block;
    padding-left: ${Spacing.md};
    margin-bottom: ${Spacing.md};
    @media (min-width: 865px) {
        padding: 0;
        margin-bottom: 0;
        margin-top: 0;
        margin-right: ${props => props.marginRight || `${Spacing.lg}`};
    }
    :not(:last-child) {
        margin-right: ${Spacing.lg};
    }
    :last-child {
        margin-right: 0;
    }
    .mobile-navigation & {
        display: block;
        margin-top: 0;
    }
`;

export const NavLink = styled(Link)`
    color: ${Colors.darkGrey};
    font-weight: bold;
    text-decoration: none;
    font-size: ${Typography.headingLevelTwoFontSize};

    @media (min-width: 865px) {
        font-size: inherit;
    }

    :hover {
        text-decoration: none;
        color: ${Colors.black};
        padding-bottom: calc(${Spacing.xs} / 3);
        border-bottom: 2px solid ${Colors.black};
    }
`;

export const NavAnchor = styled.a`
    color: ${Colors.darkGrey};
    font-weight: bold;
    text-decoration: none;
    font-size: ${Typography.headingLevelTwoFontSize};
    cursor: pointer;

    @media (min-width: 865px) {
        font-size: inherit;
    }

    :hover {
        text-decoration: none;
        color: ${Colors.black};
        padding-bottom: calc(${Spacing.xs} / 3);
        border-bottom: 2px solid ${Colors.black};
    }
`;

export const MobileButton = styled.button`
    background: none;
    border: 0;
    font-size: ${Typography.headingLevelOneFontSize};
    font-weight: bold;

    @media (min-width: 865px) {
        display: none;
    }
`;
