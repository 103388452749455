import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';
import { IConversation, IConversationOverview, IPostMessage } from 'types/message.types';

export class MessagesAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    getConversations() {
        return this.client.get<IConversationOverview[]>(`/conversations/overview`).then(res => res.data);
    }

    getMessages(conversationId: string) {
        return this.client.get<IConversation>(`/conversations/${conversationId}`).then(res => res.data);
    }

    postMessage(serviceId: number | undefined, message: IPostMessage) {
        if (serviceId) {
            return this.client.post(`/services/${serviceId}/messages`, message);
        } else {
            return this.client.post('/conversations/general', message);
        }
    }
}

export default new MessagesAPI(Client);
