import React from 'react';
import './logo.css';
import NormalLogo from 'assets/images/logos/BMWGroupNext_BMW_MINI_Grey-Colour_RGB.svg';
import MobileLogo from 'assets/images/logos/BMWGroupNext_BMW_MINI_Grey-Colour_RGB.svg';
import { Link } from 'react-router-dom';

interface NavigationLogoProps {
    readonly href: string;
}

const NavigationLogo: React.FC<NavigationLogoProps> = ({ href }) => {
    return (
        <Link to={href}>
            <picture>
                <source media="(max-width: 865px)" srcSet={MobileLogo} />
                <source media="(min-width: 865px)" srcSet={NormalLogo} />
                <img className="logo" src={NormalLogo} alt="BMW Group Labs Logo" />
            </picture>
        </Link>
    );
};
export default NavigationLogo;
