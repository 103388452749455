import { combineReducers } from 'redux';
import { createReducer } from 'deox';
import { loadPublicServiceDetails, loadServiceDetails } from './service-details.actions';
import { IService } from 'types';
import ServiceUtils from 'utils/service.utils';

export const defaultIsFetchingState = false;
export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
    handleAction([loadServiceDetails.next, loadPublicServiceDetails.next], () => true),
    handleAction(
        [
            loadServiceDetails.complete,
            loadServiceDetails.error,
            loadPublicServiceDetails.complete,
            loadPublicServiceDetails.error,
        ],
        () => false,
    ),
]);

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction([loadServiceDetails.complete, loadPublicServiceDetails.complete], () => defaultErrorState),
    handleAction([loadServiceDetails.error, loadPublicServiceDetails.error], (_, { payload }) => payload),
]);

export const defaultCurrentServiceState: IService = ServiceUtils.createEmptyService();
export const currentServiceReducer = createReducer(defaultCurrentServiceState, handleAction => [
    handleAction([loadServiceDetails.complete, loadPublicServiceDetails.complete], (_, { payload }) => payload),
    handleAction([loadServiceDetails.error, loadPublicServiceDetails.error], status => status),
]);

export type IServiceDetailsStore = ReturnType<typeof serviceDetailsReducer>;
export const serviceDetailsReducer = combineReducers({
    isFetching: isFetchingReducer,
    error: errorReducer,
    currentService: currentServiceReducer,
});
