import React from 'react';
import styled, { css } from 'styled-components';
import { Colors, Gradients, Spacing } from 'config/styling.constants';
import DefaultImage from 'assets/images/Bild_2.jpg';
import ReactPlayer from 'react-player';
import { ServiceSectionContentType } from 'types';

export interface ShowcaseBlockProps {
    readonly swap?: boolean;
    readonly greyBackground?: boolean;
    readonly svg?: string;
    readonly contentType?: ServiceSectionContentType;
    readonly contentUrl?: string;
}

const Section = styled.section`
    max-width: 1024px;

    margin-top: ${Spacing.md};
`;

const BlockContent = styled.section`
    @media (min-width: 768px) {
        display: flex;
    }
`;

const ImageBox = styled.div`
    background-color: ${Colors.lightGrey};
    @media (min-width: 768px) {
        flex: 2;
    }
`;

const Image = styled.img`
    width: 100%;
    max-height: 315px;
    @media (min-width: 640px) {
        max-height: 100%;
        object-fit: cover;
    }
`;

const BlockInfo = styled.div<ShowcaseBlockProps>`
    padding: ${Spacing.md};
    ${props =>
        props.greyBackground &&
        css`
            background-color: ${Colors.lightGrey};
        `}
    ${props =>
        props.swap &&
        css`
            order: -1;
        `}
	flex: 1;
`;

const SvgBlock = styled.div`
    background-image: ${Gradients.defaultBlue};
    height: 100%;
    padding: ${Spacing.lg};
`;

const SvgImage = styled.img`
    height: 100%;
    width: 100%;
    max-height: 250px;
`;

const ShowCaseSection: React.FC<ShowcaseBlockProps> = ({
    children,
    swap,
    greyBackground,
    svg,
    contentType,
    contentUrl,
}) => {
    return (
        <Section>
            <BlockContent>
                {contentType !== 'CUSTOM' && (
                    <ImageBox>
                        {contentUrl && (
                            <>
                                {contentType === 'IMAGE' && <Image src={contentUrl} alt={contentUrl} />}
                                {contentType === 'VIDEO' && (
                                    <ReactPlayer
                                        width={'100%'}
                                        height={'100%'}
                                        style={{ minHeight: 315 }}
                                        url={contentUrl}
                                    />
                                )}
                            </>
                        )}
                        {svg && (
                            <SvgBlock>
                                <SvgImage src={svg} alt={svg} />
                            </SvgBlock>
                        )}
                        {!contentUrl && !svg && <Image src={DefaultImage} alt="default" />}
                    </ImageBox>
                )}
                <BlockInfo swap={swap} greyBackground={greyBackground}>
                    {children}
                </BlockInfo>
            </BlockContent>
        </Section>
    );
};

export default ShowCaseSection;
