import React from 'react';
import PageLayout from 'components/page.layout';
import { Form, Col } from 'react-bootstrap';
import { Typography } from 'config/styling.constants';
import styled from 'styled-components';

const Title = styled.div`
    font-family: ${Typography.boldFontFamily};
    font-size: ${Typography.headingLevelTwoFontSize};
`;

export default function Cookies() {
    return (
        <PageLayout title="Cookies">
            <section>
                <Form.Row style={{ marginTop: '40px', padding: '40px' }}>
                    <Col>
                        <Title> Cookies settings</Title>
                    </Col>
                    <Col>
                        <p>BMW Group Labs does not use cookies.</p>
                        <p>
                            Local storage is used to store information about the current session. This includes: access
                            token, token expiry, OAuth state, user role and the URL the user has navigated to.
                        </p>
                    </Col>
                </Form.Row>
                <Form.Row style={{ marginTop: '40px', padding: '40px' }}>
                    <Col>
                        <Title>What is a Cookie?</Title>
                    </Col>
                    <Col>
                        <p>
                            A cookie is a small file that stores Internet settings. Almost every website uses cookie
                            technology. It is downloaded by your Internet browser on the first visit to a website. The
                            next time this website is opened with the same user device, the cookie and the information
                            stored in it is either sent back to the website that created it (first-party cookie) or sent
                            to another website it belongs to (third-party cookie). This enables the website to detect
                            that you have opened it previously with this browser and in some cases to vary the displayed
                            content.
                        </p>
                        <p>
                            Some cookies are extremely useful, as they can improve the user experience on opening a
                            website that you have already visited a number of times. Provided you use the same user
                            device and the same browser as before, cookies remember for example your preferences, how
                            you use a site, and adapt the displayed offerings to be more relevant to your personal
                            interest and needs.
                        </p>
                        <p>
                            Depending on their function and intended purpose, cookies can be divided into four
                            categories*: essential cookies, performance cookies, functional cookies, cookies for
                            marketing purposes.
                        </p>
                    </Col>
                </Form.Row>
            </section>
        </PageLayout>
    );
}
