import React, { ChangeEvent, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { loadPublicServices } from 'redux/servicesOverview/services-overview.actions';
import { selectServicesErrorState, selectServicesItems } from 'redux/servicesOverview/services-overview.selector';
import { IServiceOverview, RootStore } from 'types';
import { ThunkDispatch } from 'redux-thunk';
import ServiceTile from 'components/blocks/services-section/services-tile';
import ServiceFilter from 'components/shared/service-filter';
import { toast } from 'react-toastify';

export const Container = styled.div`
    padding-bottom: 3rem;
`;
interface ServiceGridProps {
    services: IServiceOverview[];
    error: string;
    loadServices: () => void;
}

interface ServiceGridState {
    filterByMarkets: string;
    filterByNames: string;
    filterByStatusActive: boolean;
}

type FILTER_TYPE = 'MARKETS' | 'NAME' | 'ACTIVE';

interface FilterType {
    [key: string]: (service: IServiceOverview) => boolean;
}

export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export class ServiceGrid extends React.Component<ServiceGridProps, ServiceGridState> {
    filters: FilterType = {
        status: (service: IServiceOverview) => !this.state.filterByStatusActive || service.active,
        market: (service: IServiceOverview) =>
            (this.state.filterByMarkets !== '' &&
                service.markets &&
                service.markets.includes(this.state.filterByMarkets)) ||
            this.state.filterByMarkets === '',
        name: (service: IServiceOverview) =>
            !this.state.filterByNames || service.name.toLowerCase().includes(this.state.filterByNames.toLowerCase()),
    };
    filterKeys = Object.keys(this.filters);

    constructor(props: ServiceGridProps) {
        super(props);
        this.state = {
            filterByMarkets: '',
            filterByNames: '',
            filterByStatusActive: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.loadServices();
    }

    componentDidUpdate() {
        if (this.props.error) {
            toast.error('Failed to load Services');
        }
    }

    handleChange(event: ChangeEvent, filter: FILTER_TYPE) {
        switch (filter) {
            case 'MARKETS':
                const selectEvent = event as ChangeEvent<HTMLSelectElement>;
                this.setState({ filterByMarkets: selectEvent.target.value });
                break;
            case 'NAME':
                const inputEvent = event as ChangeEvent<HTMLInputElement>;
                this.setState({ filterByNames: inputEvent.target.value });
                break;
            case 'ACTIVE':
                const statusEvent = event as ChangeEvent<HTMLInputElement>;
                this.setState({ filterByStatusActive: statusEvent.target.checked });
                break;
        }
    }

    render() {
        let services = this.props.services;

        services = services.filter(item => {
            return this.filterKeys.every(key => {
                return this.filters[key](item);
            });
        });

        return (
            <Container>
                <ServiceFilter handleChange={this.handleChange} />
                <Grid>
                    {services && services.length > 0 && (
                        <Fragment>
                            {services.map(serviceItem => (
                                <ServiceTile
                                    key={serviceItem.id}
                                    service={serviceItem}
                                    to={'/services-overview/' + serviceItem.id}
                                />
                            ))}
                        </Fragment>
                    )}
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    error: selectServicesErrorState(state),
    services: selectServicesItems(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadServices: () => dispatch(loadPublicServices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceGrid);
