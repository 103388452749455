import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'deox';
import { loginAction, logoutAction } from './redux/session/session.actions';
import { connect } from 'react-redux';
import { fetchToken } from './utils/login-utils';
import React, { useEffect, useState } from 'react';
import { selectSessionError, selectSessionToken, selectSessionUsername } from './redux/session/session.selector';
import { RootStore } from './types';
import { useHistory, useLocation } from 'react-router-dom';
import LocalStorageService from './utils/LocalStorageService';
import { DEFAULT_FROM } from './config/constants';
import { useTokenError } from './utils/login-utils';
import { toast } from 'react-toastify';
import { ErrorResponse, getErrorMessage } from './utils/toast-utils';

interface LoginHandlerProps {
    login: (token: string, expiry: Date, termsAccepted: boolean) => Promise<AnyAction>;
    username: string | null;
    error?: ErrorResponse;
    sessionToken: string | null;
    logoutAction: () => void;
}

const LoginHandler: React.FC<LoginHandlerProps> = ({ login, error, username, sessionToken, logoutAction }) => {
    const [token, setToken] = useState('');
    const [expiresIn, setExpiresIn] = useState(0);
    const [successRequestState, setSuccessRequestState] = useState('');

    !token &&
        fetchToken().then(result => {
            if (result) {
                setToken(result.token);
                setExpiresIn(result.expiresIn);
                setSuccessRequestState(result.state);
            }
        });

    const { error: tokenError, errorDescription, state: errorRequestState } = useTokenError();
    const history = useHistory();
    const location = useLocation();
    const savedState = LocalStorageService.getState();
    const returnedState = successRequestState || errorRequestState;

    // Check if a redirect from OAuth took place and log the user in
    useEffect(() => {
        if (!token && !tokenError) {
            // no redirect from OAuth, ignore
            return;
        }

        if (returnedState && returnedState !== savedState) {
            // Security check: Ignore request if state differs
            console.error('Login error: State is wrong');
            LocalStorageService.removeSession();
            logoutAction();
            return;
        }

        if (error && error.response) {
            LocalStorageService.removeSession();
            toast.error(`Login failed: ${getErrorMessage(error)}`);
        } else if (tokenError) {
            console.error(`Login error: ${tokenError}: ${errorDescription}`);
            LocalStorageService.removeSession();
            logoutAction();
        } else {
            const noExistingSession = !sessionToken && !username;
            const oAuthCalledBack = token && expiresIn;
            // Login: Try to fetch user from backend
            if (oAuthCalledBack && noExistingSession) {
                login(token, new Date(Date.now() + expiresIn * 1000), false);
            }
        }
    }, [
        login,
        logoutAction,
        token,
        username,
        expiresIn,
        sessionToken,
        returnedState,
        savedState,
        tokenError,
        error,
        errorDescription,
    ]);

    // Forward the user to the authenticated page
    useEffect(() => {
        if (username != null && location.pathname === '/') {
            history.push(LocalStorageService.getFrom() || DEFAULT_FROM);
            LocalStorageService.removeFrom();
        }
    }, [username, history, location]);

    return null;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => ({
    login: (token: string, expiry: Date, termsAccepted: boolean) =>
        dispatch(loginAction(token, expiry, false, termsAccepted)),
    logoutAction: () => dispatch(logoutAction()),
});

const mapStateToProps = (state: RootStore) => ({
    username: selectSessionUsername(state),
    error: selectSessionError(state),
    sessionToken: selectSessionToken(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginHandler);
