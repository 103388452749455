import { combineReducers } from 'redux';
import { createReducer } from 'deox';
import { loadPublicServices, loadServices } from './services-overview.actions';
import { IServiceOverview } from 'types';

export const defaultIsFetchingState = false;
export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
    handleAction([loadServices.next, loadPublicServices.next], () => true),
    handleAction(
        [loadServices.complete, loadServices.error, loadPublicServices.complete, loadPublicServices.error],
        () => false,
    ),
]);

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction([loadServices.complete, loadPublicServices.complete], () => defaultErrorState),
    handleAction([loadServices.error, loadPublicServices.error], (_, { payload }) => payload),
]);

export const defaultServicesState: IServiceOverview[] = [];
export const servicesReducer = createReducer(defaultServicesState, handleAction => [
    handleAction(
        [loadServices.complete, loadPublicServices.complete],
        (state, { payload }) => payload.services || state,
    ),
    handleAction([loadServices.error, loadPublicServices.error], status => status),
]);

export const defaultLastUpdatedState = 0;
export const lastUpdatedReducer = createReducer(defaultLastUpdatedState, handleAction => [
    handleAction(
        [loadServices.complete, loadPublicServices.complete],
        (state, { payload }) => payload.receivedAt || state,
    ),
    handleAction([loadServices.error, loadPublicServices.error], status => status),
]);

export type IServicesStore = ReturnType<typeof serviceOverviewReducer>;
export const serviceOverviewReducer = combineReducers({
    isFetching: isFetchingReducer,
    error: errorReducer,
    services: servicesReducer,
    lastUpdated: lastUpdatedReducer,
});
