import React, { Fragment, SyntheticEvent, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Colors, Gradients, Spacing, Typography } from 'config/styling.constants';
import { LOGIN_MOBILE_NAVIGATION, LOGIN_NAVIGATION, PUBLIC_NAVIGATION } from 'config/constants';
import Logo from './logo';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootStore } from 'types';
import { ThunkDispatch } from 'redux-thunk';
import { logoutAction } from 'redux/session/session.actions';
import { selectSessionIsAuthenticated, selectSessionUsername } from 'redux/session/session.selector';
import SelectVehicle from './vehicle.dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownComponent from './dropdown';
import LoadingBar from 'react-redux-loading-bar';
import MobileNavigation from './mobile-navigation';
import {
    MobileButton,
    MobileNavigationLinks,
    NavAnchor,
    NavigationItem,
    NavigationLinks,
    NavLink,
} from './navigation-components';
import { enableTour } from 'redux/configuration/configuration.actions';
import { getOAuthState, getOAuthUrl } from '../../utils/login-utils';
import './navigation.css';

// outsource Container component
const LogoContainer = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media (max-width: 1050px) {
        margin-left: ${Spacing.sm};
    }
`;

const NavigationBar = styled.nav`
    height: 60px;
    max-width: 1024px;
    margin: 0 auto;

    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
    @media (max-width: 865px) {
        height: auto;
    }
    @media (max-width: 1050px) {
        margin-left: ${Spacing.sm};
    }
`;

export const DropdownLink = styled(Link)`
    color: ${Colors.darkGrey};
    width: 100%;
    font-weight: bold;
    text-decoration: none;
    font-size: ${Typography.headingLevelTwoFontSize};
    padding: ${Spacing.xs} ${Spacing.sm};
    display: inline-block;

    font-size: inherit;
    :hover {
        text-decoration: none;
        color: ${Colors.darkGrey};
        background-color: ${Colors.transparentBlue};
    }
`;

const DropdownAction = styled.span`
    color: ${Colors.darkGrey};
    width: 100%;
    font-weight: bold;
    text-decoration: none;
    font-size: ${Typography.headingLevelTwoFontSize};
    padding: ${Spacing.xs} ${Spacing.sm};
    display: inline-block;

    font-size: inherit;
    :hover {
        text-decoration: none;
        color: ${Colors.darkGrey};
        background-color: ${Colors.transparentBlue};
    }
`;
const UserIcon = <FontAwesomeIcon icon="user" color="grey" style={{ marginRight: '5px' }} />;

interface NavigationProps {
    username: string | null;
    isAuthenticated?: boolean;
    logoutAction: () => void;
    toggleTour: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ username, isAuthenticated, logoutAction, toggleTour }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [width, setWidth] = React.useState(window.innerWidth);
    const [url, setUrl] = React.useState('');

    // when the username is too long it should be sliced
    const formatUsername = (username: string): string => {
        if (username.length > 30) {
            if (username.includes('@')) {
                return `${username.slice(0, 10)}...${username.slice(username.indexOf('@'))}`;
            } else {
                return `${username.slice(0, 10)}...`;
            }
        } else {
            return username;
        }
    };

    useEffect(() => {
        const updateWidth = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    useEffect(() => {
        (async () => {
            setUrl(await getOAuthUrl(getOAuthState()));
        })();
    }, []);

    const logout = useCallback(
        (event: SyntheticEvent) => {
            event.preventDefault();
            logoutAction();
        },
        [logoutAction],
    );

    const navItems = isAuthenticated ? (width <= 865 ? LOGIN_MOBILE_NAVIGATION : LOGIN_NAVIGATION) : PUBLIC_NAVIGATION;
    return (
        <Fragment>
            <LoadingBar style={{ backgroundImage: Gradients.defaultBlue }} showFastActions />
            <LogoContainer>
                <Logo href="/" />

                <MobileButton
                    onClick={() => setIsOpen(!isOpen)}
                    aria-haspopup="true"
                    aria-controls="mobile-nav"
                    aria-expanded="false"
                    open-nav
                >
                    &#x2630; <span className="sr-only">Open menu</span>
                </MobileButton>
            </LogoContainer>
            <NavigationBar>
                <MobileNavigationLinks>{isAuthenticated && <SelectVehicle></SelectVehicle>}</MobileNavigationLinks>
                <NavigationLinks>
                    {navItems.map((item, index) => (
                        <NavigationItem key={index} id={`nav-${item.name.toLowerCase()}`}>
                            <NavLink to={item.href}>{item.name}</NavLink>
                        </NavigationItem>
                    ))}
                </NavigationLinks>
                <NavigationLinks style={{ justifyContent: 'flex-end' }}>
                    {!isAuthenticated && url && (
                        <NavigationItem marginRight={Spacing.sm}>
                            <NavAnchor href={url}>Login</NavAnchor>
                        </NavigationItem>
                    )}
                    {isAuthenticated && !username && (
                        <NavigationItem marginRight={Spacing.sm}>
                            <NavAnchor onClick={logout}>Logout</NavAnchor>
                        </NavigationItem>
                    )}
                    {isAuthenticated && username && (
                        <Fragment>
                            <SelectVehicle />
                            <div>
                                <DropdownComponent
                                    title={formatUsername(username)}
                                    icon={UserIcon}
                                    id="nav-user-dropdown"
                                >
                                    <div>
                                        <DropdownAction onClick={() => toggleTour()}>I need help!</DropdownAction>
                                        <DropdownLink to="/settings">Settings</DropdownLink>
                                        <DropdownLink to="/" onClick={logout}>
                                            Logout
                                        </DropdownLink>
                                    </div>
                                </DropdownComponent>
                            </div>
                        </Fragment>
                    )}
                </NavigationLinks>
            </NavigationBar>

            <MobileNavigation
                isOpen={isOpen}
                onClick={() => setIsOpen(!isOpen)}
                navItems={navItems}
                isAuthenticated={isAuthenticated ? isAuthenticated : false}
                logout={logout}
                toggleTour={toggleTour}
            />
        </Fragment>
    );
};

const mapStateToProps = (state: RootStore) => ({
    username: selectSessionUsername(state),
    isAuthenticated: selectSessionIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    logoutAction: () => dispatch(logoutAction()),
    toggleTour: () => dispatch(enableTour.toggle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
