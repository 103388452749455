import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { APP_VERSION, BMW_ENVIRONMENT, FOOTER_ITEMS } from 'config/constants';
import { Colors, Spacing } from 'config/styling.constants';

export const FooterWrapper = styled.footer`
    background-color: ${Colors.lightElementGrey};
    padding: ${Spacing.sm};
`;

export const Container = styled.div`
    max-width: 1024px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 865px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const FooterLinks = styled.ul`
    margin-top: 10px;
`;

export const FooterItem = styled.li`
    display: inline-block;
    margin-top: 0;

    @media (max-width: 865px) {
        display: block;
        margin-bottom: ${Spacing.sm};
    }

    &:not(:last-child) {
        margin-right: ${Spacing.md};
    }
`;

export const FooterLink = styled(Link)`
    color: ${Colors.textGrey};
    text-decoration: none;

    &:hover {
        color: ${Colors.black};
    }

    &::before {
        margin-right: ${Spacing.xs};
        width: 10px;
        height: 10px;

        overflow: hidden;
    }
`;

export const Copyright = styled.div`
    @media (max-width: 865px) {
        margin-left: ${Spacing.md};
        margin-bottom: ${Spacing.sm};
    }
`;

const Footer: React.FC = () => {
    return (
        <FooterWrapper>
            <Container>
                <Copyright>
                    <strong>© BMW AG {new Date().getFullYear()}</strong> <br />
                    <span>
                        Version: {APP_VERSION}
                        {(BMW_ENVIRONMENT === 'INT' || BMW_ENVIRONMENT === 'LOCAL') && ` (${BMW_ENVIRONMENT})`}
                    </span>
                </Copyright>
                <FooterLinks>
                    {FOOTER_ITEMS.map((value, index) => (
                        <FooterItem key={index}>
                            <FooterLink to={value.href}>{value.name}</FooterLink>
                        </FooterItem>
                    ))}
                </FooterLinks>
            </Container>
        </FooterWrapper>
    );
};
export default Footer;
