import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { loadServices } from '../../redux/servicesOverview/services-overview.actions';
import { IServiceOverview, RootStore } from 'types';
import { ThunkDispatch } from 'redux-thunk';
import ServiceTile from 'components/blocks/services-section/services-tile';
import {
    selectServicesErrorState,
    selectServicesIsFetchingState,
    selectSubscribedServices,
} from 'redux/servicesOverview/services-overview.selector';
import Button from 'components/button';
import { Spacing } from 'config/styling.constants';
import { toast } from 'react-toastify';

const ServiceGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${Spacing.md};
    min-height: 300px;

    @media (min-width: 960px) {
        article {
            margin-top: 0;
        }
    }
`;

interface DashboardGridState {
    isError: boolean;
}

interface DashboardGridProps {
    loading: boolean;
    services: IServiceOverview[];
    error: string;
    loadServices: () => void;
}

class DashboardServiceGrid extends React.Component<DashboardGridProps, DashboardGridState> {
    constructor(props: DashboardGridProps) {
        super(props);
        this.state = { isError: false };
    }

    componentDidMount() {
        this.props.loadServices();
    }

    render() {
        const { error, loading, services } = this.props;

        if (error && !this.state.isError) {
            toast.error('Failed to load Services');
            this.setState({ isError: true });
        }

        return (
            <Fragment>
                <ServiceGrid>
                    {services &&
                        services.length > 0 &&
                        services.map(service => (
                            <ServiceTile key={service.id} service={service} to={`/services/${service.id}`} />
                        ))}
                    {services.length === 0 && !loading && !error && (
                        <div>
                            <span>
                                You are not subscribed to any services. Go to Service page and subscribe to a services.
                            </span>
                            <Button to={'/services'}>Go to Services</Button>
                        </div>
                    )}
                </ServiceGrid>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    loading: selectServicesIsFetchingState(state),
    error: selectServicesErrorState(state),
    services: selectSubscribedServices(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadServices: () => dispatch(loadServices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardServiceGrid);
