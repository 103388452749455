import React from 'react';
import styled, { css } from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';

interface PageHeaderProps {
    readonly title?: string;
    readonly image?: string;
    readonly isLarge?: boolean;
    readonly subtitle?: string;
}

export const Header = styled.header<PageHeaderProps>`
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	${props => props.image && `background-image: url(${props.image});`}
    padding: ${Spacing.md} ${Spacing.md} ${Spacing.xl};
	position: relative;
	overflow: hidden;
	z-index: 0;
	background-color: ${Colors.petrol};

	${props =>
        props.isLarge &&
        css`
            min-height: 25vh;
            @media (min-width: 640px) {
                min-height: 50vh;
            }
        `} 
    @media(min-width: 1024px) {
		padding-top: ${Spacing.xl};
	}
`;

const Container = styled.div`
    max-width: 1024px;
    margin: 0 auto;
`;

const Title = styled.h1`
    color: ${Colors.white};
    margin-bottom: ${Spacing.lg};
    margin-left: calc((${Spacing.md} / 2) * -1);
    max-width: 20ch;

    @media (min-width: 580px) {
        margin-left: 0;
    }
    @media (min-width: 768px) {
        padding-right: ${Spacing.xs};
        max-width: 50%;
    }
    @media (min-width: 1024px) {
        font-size: ${Typography.headerTitleFontSize};
    }
`;

const PageHeader: React.FC<PageHeaderProps> = ({ image, title, subtitle, isLarge }) => {
    return (
        <Header image={image} isLarge={isLarge}>
            <Container>
                <Title>{title}</Title>
                <p>{subtitle}</p>
            </Container>
        </Header>
    );
};
export default PageHeader;
