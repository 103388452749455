import { IConfigMarket, IServiceMarket, RequirementCountryItem } from 'types';

export interface SelectOptions {
    value: string;
    label: string;
}

class MarketsUtils {
    private markets: IConfigMarket[] = [];

    mapServiceRequirements(markets: IServiceMarket[]): RequirementCountryItem[] {
        return markets
            .map(market => {
                return {
                    countryCode: market.code.toLocaleLowerCase(),
                    label: this.getCountryLabel(market),
                };
            })
            .filter(element => element.label !== '');
    }

    mapServiceTile(countryCodes: string[]): string {
        return countryCodes
            .map(code => this.getCountryName(code) || '')
            .filter(title => title !== '')
            .join(', ');
    }

    getMarketOptions(): SelectOptions[] {
        return this.markets
            .map((item: IConfigMarket) => {
                return {
                    value: item.code,
                    label: item.name,
                };
            })
            .filter(element => element.label !== '');
    }

    getCountryName(countryCode: string): string {
        const market = this.markets.find(item => item.code === countryCode);
        return market ? market.name : '';
    }

    getCountryLabel(market: IServiceMarket): string {
        const label = market.cities.length > 0 ? market.cities.join(', ') : this.getCountryName(market.code);

        return `${label} (${market.code})`;
    }

    getMarketMapping(): IConfigMarket[] {
        return this.markets;
    }

    setMarkets(markets: IConfigMarket[]) {
        this.markets = markets;
    }

    helperSortIServiceMarkets(a: IServiceMarket, b: IServiceMarket) {
        const a1 = a.cities.length === 0 ? this.getCountryName(a.code) : a.cities[0];
        const b1 = b.cities.length === 0 ? this.getCountryName(b.code) : b.cities[0];
        return a1 > b1 ? 1 : b1 > a1 ? -1 : 0;
    }

    helperSortMarkets(a: string, b: string) {
        return this.getCountryName(a) > this.getCountryName(b)
            ? 1
            : this.getCountryName(b) > this.getCountryName(a)
            ? -1
            : 0;
    }
}

export default new MarketsUtils();
