import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';
import { MarketingEntry } from '../../types/marketing.types';

class MarketingAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    public getMarketing(): Promise<MarketingEntry[]> {
        return this.client.get<MarketingEntry[]>(`/marketing`).then(response => response.data);
    }
}
const api = new MarketingAPI(Client);
export default api;
