import React, { Fragment } from 'react';
import Button from 'components/button';
import { SubscriptionActionType } from 'types';
import styled from 'styled-components';
import { Colors, Typography } from 'config/styling.constants';

interface SubscriptionComponentProps {
    serviceId?: number;
    matchRequirements?: boolean;
    isSubscribed?: boolean;
    isActive: boolean;
    vehicle?: string;
    subscribeAction: (payload: SubscriptionActionType) => void;
    maxSubscriptionCount: number | null;
    subscribedUsersCount: number;
}

const ButtonHint = styled.span`
    display: block;
    font-size: ${Typography.baseFontSize};
    font-family: ${Typography.boldFontFamily};
    letter-spacing: ${Typography.bodyTextLetterSpacing};
    color: ${Colors.white};
`;

const SubscriptionComp: React.FC<SubscriptionComponentProps> = ({
    matchRequirements,
    isSubscribed,
    isActive,
    subscribeAction,
    vehicle,
    maxSubscriptionCount,
    subscribedUsersCount,
}) => {
    if (!isActive && !isSubscribed) {
        // Service is not active
        return (
            <Fragment>
                <ButtonHint>Service is inactive</ButtonHint>
            </Fragment>
        );
    } else if (!isSubscribed && !matchRequirements) {
        // requirements do not match
        return (
            <Fragment>
                <Button disabled={true} outline header>
                    Requirements do not match
                </Button>
                {vehicle && <ButtonHint>Current vehicle is not supported.</ButtonHint>}
                {!vehicle && <ButtonHint>Please select a vehicle.</ButtonHint>}
            </Fragment>
        );
    } else if (!isSubscribed && matchRequirements) {
        // requirements match and user is not subscribed
        return (
            <Fragment>
                {maxSubscriptionCount && (
                    <div style={{ color: '#8ccad5' }}>
                        {' '}
                        Remaining seats available: {maxSubscriptionCount - subscribedUsersCount} of{' '}
                        {maxSubscriptionCount}{' '}
                    </div>
                )}
                <Button style={{ marginTop: '0.5em' }} onClick={() => subscribeAction('SUBSCRIBE')} outline header>
                    Subscribe current vehicle
                </Button>
                {matchRequirements && vehicle && <ButtonHint>{vehicle}</ButtonHint>}
            </Fragment>
        );
    } else if (isSubscribed) {
        // user is subscribed therefore a unsubscribe button is displayed
        return (
            <Fragment>
                <Button onClick={() => subscribeAction('UNSUBSCRIBE')} outline header>
                    Unsubscribe current vehicle
                </Button>
                {!matchRequirements && <ButtonHint>Current vehicle is not supported.</ButtonHint>}
                {matchRequirements && vehicle && <ButtonHint>{vehicle}</ButtonHint>}
            </Fragment>
        );
    }
    return <Fragment>Unknown state</Fragment>;
};

export default SubscriptionComp;
