import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from 'config/styling.constants';

interface PrivacyAndTermsModalProps {
    content: string;
    title: string;
    linkTitle: string;
}

interface PrivacyAndTermsModalState {
    open: boolean;
}

const Container = styled.div`
    padding: 10px;
    font-family: ${Typography.baseFontFamily};
`;

class PrivacyAndTermsModal extends React.Component<PrivacyAndTermsModalProps, PrivacyAndTermsModalState> {
    constructor(props: PrivacyAndTermsModalProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    render() {
        const closeModal = () => this.setState({ open: false });

        const openModal = () => this.setState({ open: true });

        return (
            <Fragment>
                <Link to="#" onClick={openModal} style={{ paddingLeft: '5px' }}>
                    {this.props.linkTitle}
                </Link>

                <Modal show={this.state.open} onHide={closeModal} size={'lg'} scrollable={true}>
                    <Form onSubmit={closeModal}>
                        <Modal.Header closeButton translate>
                            <Modal.Title>{this.props.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                            <Container>
                                <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
                            </Container>
                        </Modal.Body>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default PrivacyAndTermsModal;
