import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { selectSessionIsAuthenticated, selectTermsAccepted } from 'redux/session/session.selector';
import { RootStore } from 'types';
import { connect } from 'react-redux';
import LocalStorageService from '../utils/LocalStorageService';
import { forwardToLogin, getOAuthState } from '../utils/login-utils';
import PlatformTermsPage from 'pages/platform-terms/platform-terms-page';

interface AuthRouteProps extends RouteProps {
    isAuthenticated?: boolean;
    termsAccepted: boolean;
}

class AuthenticatedRoute extends Route<AuthRouteProps> {
    render() {
        // If the username is empty but the token is known the user is authenticated but has not accepted the terms
        if (this.props.isAuthenticated) {
            return this.props.termsAccepted ? <Route {...this.props} /> : <PlatformTermsPage />;
        } else {
            const from = this.props.location && this.props.location.pathname;
            LocalStorageService.saveFrom(from);
            forwardToLogin(getOAuthState());
            return null;
        }
    }
}

const mapStateToProps = (state: RootStore) => ({
    isAuthenticated: selectSessionIsAuthenticated(state),
    termsAccepted: selectTermsAccepted(state), // if the user is known he must have been registered before,
});

export default connect(mapStateToProps)(AuthenticatedRoute);
