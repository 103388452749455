import React from 'react';
import PageLayout from 'components/page.layout';
import Collapse from '../../components/collapse';
import styled from 'styled-components';

const Container = styled.div`
    margin: 60px 0;
    padding: 0 40px;
`;

export default function Faq() {
    return (
        <PageLayout title="FREQUENTLY ASKED QUESTIONS">
            <Container>
                <Collapse title="What is BMW Group Labs?">
                    <p>
                        BMW Group Labs is a platform that offers Beta Services for you as a BMW or Mini driver to test.
                        We thereby give you the chance to contribute to our development by giving feedback to the beta
                        services. We are looking forward to receiving your input on our services.
                    </p>
                </Collapse>

                <Collapse title="How can I be a part of it?">
                    <p>
                        Just go to the service page of the service you want to participate and check the requirements.
                        If they match your situation and your car you can register for the service. We will then get in
                        contact with you or automatically deploy the service into your car.
                    </p>
                </Collapse>

                <Collapse title="How can I stop testing a service?">
                    <p>
                        If you do no longer want to be part of the testing team of a service just get in contact with us
                        via the specific service mail address or via labs@bmwgroup.com.
                    </p>
                </Collapse>

                <Collapse title="How can I get support?">
                    <p>
                        Should you encounter any problems or concerns please get in contact with us via
                        labs@bmwgroup.com.
                    </p>
                </Collapse>
            </Container>
        </PageLayout>
    );
}
