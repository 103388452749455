import { LOGIN_URL, REDIRECT_URI } from '../config/constants';
import LocalStorageService from './LocalStorageService';
import { v4 as uuidv4 } from 'uuid';
import { createHash } from 'crypto';

import queryString from 'query-string';
import api from './api/token.api';

export async function getOAuthUrl(state: string): Promise<string> {
    let brand = 'bmw';
    switch (window.location.hostname) {
        case 'labs.mini.com':
        case 'int-emea.labs.mini.com':
            brand = 'mini';
            break;
        default:
            brand = 'bmw';
    }

    const minLength = 43;
    const maxLength = 128;
    const randomLength = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
    const codeVerifier = generateRandomString(randomLength);
    LocalStorageService.saveCodeVerifier(codeVerifier);
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    return (
        LOGIN_URL +
        '&state=' +
        state +
        '&brand=' +
        brand +
        '&code_challenge=' +
        codeChallenge +
        '&code_challenge_method=S256'
    );
}

export function fetchBrand() {
    let brand = 'bmw';
    switch (window.location.hostname) {
        case 'labs.mini.com':
        case 'int-emea.labs.mini.com':
            brand = 'mini';
            break;
        default:
            brand = 'bmw';
    }
    return brand;
}

export const getOAuthState = () => LocalStorageService.getState() || LocalStorageService.saveState(uuidv4());

export async function forwardToLogin(state: string): Promise<void> {
    LocalStorageService.removeSession();
    window.location.replace(await getOAuthUrl(state));
}

const generateCodeChallenge = async (codeVerifier: string) => {
    const hashAlgorithm = 'sha256';
    const hash = createHash(hashAlgorithm)
        .update(codeVerifier)
        .digest('base64'); //TODO: after typescript upgrade change to base64url and remove replace logic
    return hash
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
};

const generateRandomString = (length: number): string => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
    }

    return result;
};

export const fetchToken = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const authCode = queryParams.get('code');
    const state = queryParams.get('state');

    if (authCode && state) {
        try {
            const bearerToken = await api.getToken({
                gcdmAuthCode: authCode,
                state,
                redirectUri: REDIRECT_URI,
                codeVerifier: LocalStorageService.getCodeVerifier() as string,
            });

            return {
                token: bearerToken.data.access_token,
                expiresIn: bearerToken.data.expires_in,
                state: state as string,
            };
        } catch (err) {
            console.log(err);
        }
    } else {
        return null;
    }
};

export const useTokenError = () => {
    const params = queryString.parse(window.location.search);
    const { error, error_description: errorDescription, state } = params;
    return { error, errorDescription, state };
};
