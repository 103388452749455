import React, { CSSProperties, Fragment } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';
import { IVehicle, RootStore } from 'types';
import {
    selectSelectedVehicle,
    selectVehicleList,
    selectVehicleListError,
    selectVehicleListIsLoading,
} from 'redux/vehicle-list/vehicle-list.selector';
import { fetchVehicleList, selectVehicleAction } from 'redux/vehicle-list/vehicle-list.actions';
import { loadServices } from 'redux/servicesOverview/services-overview.actions';
import DropdownComponent from './dropdown';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VehicleImage from 'components/vehicle-image';

const VehicleList = styled.ul`
    margin: 0;
    padding-inline-start: 0;
`;

const SelectVehicleItem = styled.li`
    padding: ${Spacing.xs} ${Spacing.sm} ${Spacing.xs} ${Spacing.xs};
    margin: 0;
    text-decoration: none;
    display: inline-flex;

    p {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    p:first-child {
        font-family: ${Typography.boldFontFamily};
    }

    p:nth-child(2) {
        color: ${Colors.darkGrey};
    }

    :not(:last-child) {
        border-bottom: 1px solid ${Colors.lightGrey};
    }

    :hover {
        background-color: ${Colors.transparentBlue};
    }
`;
const DropdownContainer = styled.div`
    min-height: ${Spacing.md};
    padding: ${Spacing.sm};
`;

const VehicleImageStyle: CSSProperties = {
    marginTop: '5px',
};

interface DropdownProps {
    vehicles?: IVehicle[];
    selectedVehicle: IVehicle | null;
    isLoading: boolean;
    error?: string;
    getVehicles: () => void;
    selectVehicle: (vehicle: IVehicle) => void;
    loadServices: () => void;
}

class NavDropdown extends React.Component<DropdownProps> {
    componentDidMount() {
        this.props.getVehicles();
    }

    changeVehicle(vehicle: IVehicle) {
        this.props.selectVehicle(vehicle);
        this.props.loadServices();
    }

    render() {
        const buttonTitle =
            this.props.selectedVehicle && this.props.selectedVehicle.model
                ? this.props.selectedVehicle.model
                : 'Select Vehicle';

        const icon = <FontAwesomeIcon icon="car" size="2x" color="grey" style={{ height: '18px' }} />;

        return (
            <DropdownComponent title={buttonTitle} icon={icon} id="nav-select-vehicle">
                <Fragment>
                    {this.props.isLoading && (
                        <DropdownContainer>
                            <span>Loading ...</span>
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                        </DropdownContainer>
                    )}

                    {!this.props.isLoading && (
                        <VehicleList>
                            {(!this.props.vehicles || this.props.vehicles.length === 0) && !this.props.isLoading && (
                                <DropdownContainer>
                                    <span>No mapped Vehicles</span>
                                </DropdownContainer>
                            )}
                            {!this.props.error &&
                                this.props.vehicles &&
                                this.props.vehicles.map(item => (
                                    <SelectVehicleItem key={item.vin} onClick={() => this.changeVehicle(item)}>
                                        <VehicleImage
                                            vin={item.vin}
                                            imageWidth={75}
                                            view="SIDE"
                                            style={VehicleImageStyle}
                                        />
                                        <div>
                                            <p>{item.model}</p>
                                            <p>{item.vin}</p>
                                        </div>
                                    </SelectVehicleItem>
                                ))}
                        </VehicleList>
                    )}

                    {this.props.error && (
                        <DropdownContainer>
                            <p>Failed to load vehicles</p>
                            <Button variant="outline-danger" onClick={() => this.props.getVehicles()}>
                                Retry
                            </Button>
                        </DropdownContainer>
                    )}
                </Fragment>
            </DropdownComponent>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    vehicles: selectVehicleList(state),
    isLoading: selectVehicleListIsLoading(state),
    error: selectVehicleListError(state),
    selectedVehicle: selectSelectedVehicle(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    getVehicles: () => dispatch(fetchVehicleList()),
    loadServices: () => dispatch(loadServices()),
    selectVehicle: (vehicle: IVehicle) => dispatch(selectVehicleAction(vehicle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavDropdown);
