import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';

export interface CollapseComponentProps {
    title: string;
}

const Container = styled.details`
    border-bottom: 2px solid ${Colors.mediumGrey};
    cursor: pointer;
    list-style: none;

    &[open] {
        padding-bottom: ${Spacing.md};
    }

    :first-child {
        border-top: 2px solid ${Colors.mediumGrey};
    }
`;

interface CollapseProps {
    open: boolean;
}

const Collapse = styled.div<CollapseProps>`
    display: ${props => (props.open ? 'inherit' : 'none')};
`;

const Label = styled.span`
    font-size: 16px;
    display: inline-block;
`;

interface CollapseHeaderProps {
    sign: string;
}

const CollapseHeader = styled.summary<CollapseHeaderProps>`
    list-style-type: none;
    font-family: ${Typography.boldFontFamily};
    display: block;
    padding: ${Spacing.md} 0;
    outline: none;

    ::-webkit-details-marker {
        display: none;
    }

    ::before {
        height: 2rem;
        width: 2rem;
        content: "${props => props.sign}";
        padding: ${Spacing.xs};
        background-color: ${Colors.darkElementGrey};
        color: ${Colors.white};
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: ${Spacing.sm};
        line-height: 1;
        font-size: 1rem;
    }
    :hover::before {
        background-color: ${Colors.black};

    }

`;

const Content = styled.div`
    font-family: ${Typography.baseFontFamily};
    font-size: 14px;

    p {
        margin: 0;
    }
`;

const CollapseComponent: React.FC<CollapseComponentProps> = props => {
    const [open, setOpen] = useState(false);
    const [sign, setSign] = useState('+');

    const onClick = () => {
        setOpen(!open);
        open ? setSign('+') : setSign('-');
    };

    return (
        <Container>
            <CollapseHeader onClick={onClick} sign={sign}>
                <Label>{props.title}</Label>
            </CollapseHeader>

            <Collapse open={open}>
                <Content>{props.children}</Content>
            </Collapse>
        </Container>
    );
};
export default CollapseComponent;
