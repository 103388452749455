import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import { Spacing } from 'config/styling.constants';
import { ADMIN_CHANNEL_ID } from '../../config/constants';

const FeedbackContainer = styled.div`
    padding: ${Spacing.sm} 0;
`;

const FeedbackSection: React.FC = () => {
    return (
        <FeedbackContainer>
            <h2>Feedback</h2>
            <p>We appreciate any feedback, questions or comments.</p>
            <Button to={`/mailbox?channelId=${ADMIN_CHANNEL_ID}`}>Give Feedback</Button>
        </FeedbackContainer>
    );
};
export default FeedbackSection;
