import React, { CSSProperties } from 'react';
import { RootStore, VehicleImageView } from 'types';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchVehicleImage } from 'redux/vehicle-list/vehicle-list.actions';
import { selectVehicleImage } from 'redux/vehicle-list/vehicle-list.selector';
import { VehicleImageMapping } from 'redux/vehicle-list/vehicle-list.reducer';

interface VehicleImageProps {
    vin: string;
    imageWidth: number;
    view: VehicleImageView;
    style?: CSSProperties;
    vehicleImageStore: VehicleImageMapping;
    getVehicleImage: (vin: string, width: number, view: VehicleImageView) => void;
}

interface VehicleImageState {
    vehicleImage?: string;
}

class VehicleImage extends React.Component<VehicleImageProps, VehicleImageState> {
    constructor(props: VehicleImageProps) {
        super(props);
        this.state = {};
        this.loadVehicleImage = this.loadVehicleImage.bind(this);
    }

    componentDidMount() {
        this.loadVehicleImage();
    }

    componentDidUpdate(prevProps: VehicleImageProps) {
        // Reload service details if selected vehicle changed
        if (this.props.vin !== prevProps.vin) {
            this.loadVehicleImage();
        }
        const key = this.props.vin + '-' + this.props.view;
        const storeImage = this.props.vehicleImageStore[key];

        if (storeImage && !this.state.vehicleImage) {
            this.setState({ vehicleImage: storeImage.image });
        }
    }

    loadVehicleImage() {
        const key = this.props.vin + '-' + this.props.view;
        const storeImage = this.props.vehicleImageStore[key];

        if (storeImage) {
            this.setState({ vehicleImage: storeImage.image });
        } else if (this.props.vin !== '') {
            this.props.getVehicleImage(this.props.vin, this.props.imageWidth, this.props.view);
        }
    }

    render() {
        if (this.state.vehicleImage) {
            return (
                <img
                    src={this.state.vehicleImage}
                    alt={this.props.vin}
                    style={{ ...this.props.style, width: this.props.imageWidth }}
                />
            );
        } else {
            return <div style={this.props.style}></div>;
        }
    }
}

const mapStateToProps = (state: RootStore) => ({
    vehicleImageStore: selectVehicleImage(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    getVehicleImage: (vin: string, width: number, view?: VehicleImageView) =>
        dispatch(fetchVehicleImage(vin, width, view)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleImage);
