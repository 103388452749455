import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';
import { Link } from 'react-router-dom';

export interface ButtonProps {
    readonly style?: CSSProperties;
    readonly outline?: boolean;
    readonly header?: boolean;
    readonly disabled?: boolean;
    readonly to?: string;
    readonly onClick?: () => void;
}

const ButtonLink = styled.div<ButtonProps>`
    padding: ${Spacing.xs} ${Spacing.md};
    display: inline-block;
    font-size: ${Typography.baseFontSize};
    font-weight: bold;
    line-height: 1;
    text-decoration: none;
    border: 0;
    margin-top: ${Spacing.sm};
    will-change: background-color;
    transition: background-color 250ms ease-out, color 250ms ease-out;
    cursor: pointer;
    text-align: center;
    scroll-behavior: smooth;

    background-color: ${Colors.cyan};
    color: ${Colors.white};

    ${props =>
        !props.disabled &&
        css`
            a,
            a:hover {
                color: ${Colors.white};
                text-decoration: none;
            }

            &:hover {
                background-color: ${Colors.petrol};
                color: ${Colors.white};
                text-decoration: none;
            }
        `}

    &:focus:active {
        outline: none;
        box-shadow: inset 0 0 5px ${Colors.black};
    }
    ${props =>
        props.outline &&
        css`
            background-color: transparent;
            border: 2px solid ${Colors.white};

            ${!props.disabled &&
                css`
                    &:hover {
                        background-color: ${Colors.white};
                        color: ${Colors.petrol};
                        text-decoration: none;
                        a {
                            color: ${Colors.petrol};
                            text-decoration: none;
                        }
                    }
                `}
        `}

    ${props => props.header && `margin-top: ${Spacing.md};`} 
	
	@media(prefers-reduced-motion) {
        transition: none;
    }
`;

const Button: React.FC<ButtonProps> = ({ header, outline, children, to, style, onClick, disabled }) => {
    const handleClick = () => {
        if (!disabled && onClick) onClick();
    };
    const button = (
        <ButtonLink header={header} outline={outline} style={style} onClick={handleClick} disabled={disabled}>
            {children}
        </ButtonLink>
    );

    return <div>{to ? <Link to={to}>{button}</Link> : button}</div>;
};
export default Button;
