import React from 'react';
import styled, { css } from 'styled-components';
import { Colors, Gradients, Spacing, Typography } from 'config/styling.constants';
import HeroDefaultImage from 'assets/images/home/landing_page_header_background3.png';

interface ServiceHeaderProps {
    readonly title?: string;
    readonly image?: string;
    readonly isLarge?: boolean;
    readonly subtitle?: string;
}

interface HeaderProps {
    readonly image?: string;
    readonly isLarge?: boolean;
}

export const Header = styled.header<HeaderProps>`
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	${props =>
        props.image
            ? `background-image: ${Gradients.serviceHeader}, url(${props.image});`
            : `background-image: ${Gradients.serviceHeader};`} 
        
    padding: ${Spacing.md} ${Spacing.md} ${Spacing.xl};
	position: relative;
	overflow: hidden;
	z-index: 0;
    background-color: ${Colors.black};
    color: ${Colors.white};

	${props =>
        props.isLarge &&
        css`
            min-height: 25vh;
            @media (min-width: 640px) {
                min-height: 50vh;
            }
        `}
	
    @media(min-width: 1145px) {
		padding-top: ${Spacing.xl};
	}
    @media(max-width: 800px) {
        background-image: ${Gradients.mobileServiceHeader};
	}
`;

const Container = styled.div`
    max-width: 1024px;
    margin: 0 auto;
`;

const Title = styled.h1`
    color: ${Colors.white};
    margin: 0 0 ${Spacing.xs};
    max-width: 50%;

    @media (min-width: 580px) {
        margin-left: 0;
    }
    @media (min-width: 768px) {
        padding-right: ${Spacing.xs};
        max-width: 50%;
    }
    @media (min-width: 1024px) {
        font-size: ${Typography.headerTitleFontSize};
    }
`;

const Info = styled.div`
    display: block;
    font-size: ${Typography.headingLevelTwoFontSize};
    line-height: ${Typography.headingLevelTwoLineHeight};
    font-family: ${Typography.boldFontFamily};
    letter-spacing: ${Typography.headingLevelTwoLetterSpacing};

    @media (min-width: 768px) {
        max-width: 50%;
        padding-right: ${Spacing.xs};
    }
`;

const ServiceHeader: React.FC<ServiceHeaderProps> = ({ image, title, subtitle, children, isLarge }) => {
    return (
        <Header image={image || HeroDefaultImage} isLarge={isLarge}>
            <Container>
                <Title>{title}</Title>
                <Info>{subtitle && <span>{subtitle}</span>}</Info>
                {children}
            </Container>
        </Header>
    );
};
export default ServiceHeader;
