import { mailboxReducer as mailbox } from './mailbox/mailbox.reducer';
import { combineReducers } from 'redux';
import { serviceOverviewReducer as servicesOverview } from 'redux/servicesOverview/services-overview.reducer';
import { serviceDetailsReducer as serviceDetails } from 'redux/serviceDetails/service-details.reducer';
import { sessionReducer as session } from 'redux/session/session.reducer';
import { vehicleListReducer as vehicleList } from 'redux/vehicle-list/vehicle-list.reducer';
import { configurationReducer as configuration } from 'redux/configuration/configuration.reducer';
import { loadingBarReducer } from 'react-redux-loading-bar';

export default combineReducers({
    session,
    servicesOverview,
    serviceDetails,
    vehicleList,
    configuration,
    mailbox,
    loadingBar: loadingBarReducer,
});
