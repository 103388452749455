import React from 'react';
import { connect } from 'react-redux';
import Joyride, { ACTIONS, CallBackProps, EVENTS, Step } from 'react-joyride';
import { selectSessionIsAuthenticated } from 'redux/session/session.selector';
import { RootStore } from 'types';
import { selectConfigurationShowTour } from 'redux/configuration/configuration.selector';
import { enableTour } from 'redux/configuration/configuration.actions';
import { ThunkDispatch } from 'redux-thunk';
import { Colors } from 'config/styling.constants';
import { RouteComponentProps, withRouter } from 'react-router';

export interface TourComponentProps extends RouteComponentProps {
    isAuthenticated: boolean;
    showTour: boolean;
    enableTour: (showTour: boolean) => void;
}

interface TourStep extends Step {
    navigateRouter?: string;
}

const stylingOptions = {
    options: { primaryColor: Colors.cyan },
};

const TourComponent: React.FC<TourComponentProps> = ({ isAuthenticated, showTour, enableTour, history }) => {
    const callback = (e: CallBackProps) => {
        if (e.action === ACTIONS.RESET) {
            enableTour(false);
        }

        const step = e.step as TourStep;
        if (step.navigateRouter && e.type === EVENTS.STEP_BEFORE) {
            history.push(step.navigateRouter);
        }
    };
    return (
        <Joyride
            steps={steps}
            run={isAuthenticated && showTour}
            continuous={true}
            hideBackButton={false}
            debug={false}
            disableCloseOnEsc={true}
            styles={stylingOptions}
            showSkipButton={true}
            callback={callback}
            disableOverlayClose={true}
        />
    );
};

const mapStateToProps = (state: RootStore) => ({
    isAuthenticated: selectSessionIsAuthenticated(state),
    showTour: selectConfigurationShowTour(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    enableTour: (showTour: boolean) => dispatch(enableTour.handleChange(showTour)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TourComponent));

const steps: TourStep[] = [
    {
        target: '#nav-select-vehicle',
        content: 'Here you can see your mapped vehicles. Select the one vehicle you want to manage.',
        hideCloseButton: true,
    },
    {
        target: '#nav-services',
        disableBeacon: true,
        navigateRouter: 'services',
        content: 'Here you can find a list of all BMW Group Labs services.',
    },
    {
        target: '.service-badge.active',
        disableBeacon: true,
        content: 'This badge indicates that the service is open for registration.',
    },
    {
        target: '.service-badge.subscribed',
        disableBeacon: true,
        content: 'This badge indicates that your selected vehicle is subscribed to the service.',
    },
    {
        target: '#nav-mailbox',
        disableBeacon: false,
        navigateRouter: 'mailbox',
        content: 'Here you can find all your Messages and Feedbacks.',
    },
    {
        target: '#nav-user-dropdown',
        disableBeacon: true,
        content: 'You can restart the tour at any time by clicking on your user name and select “Start Walkthrough”.',
    },
];
