import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Borders, Colors, Typography } from 'config/styling.constants';
import { SelectOptions } from 'utils/markets.utils';

const Label = styled.label`
    font-family: ${Typography.baseFontFamily};
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
    color: ${Colors.black};
    font-weight: bold;
`;

const Input = styled.select`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 10px 20px;
    width: 100%;
    height: 45px;
    background: white;
    border: 2px solid ${Colors.darkGrey};
    border-radius: 3px;
    font-family: ${Typography.baseFontFamily};
    font-size: 14px;
    display: block;
`;

const Wrapper = styled.div`
    position: relative;

    &:after {
        content: '';
        width: ${Borders.cssTriangleSize};
        height: ${Borders.cssTriangleSize};
        border-bottom: ${Borders.blackBorder};
        border-right: ${Borders.blackBorder};
        overflow: hidden;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(-50%, -50%) rotate(45deg);
    }
`;

const Container = styled.div`
    width: 100%;
    margin-top: 20px;
    :first-child {
        margin: 0;
    }
`;

export interface InputSelectionBoxProps {
    label: string;
    placeholder?: string;
    list: SelectOptions[];
    handleChange: (event: ChangeEvent) => void;
}

function InputSelectionBox(props: InputSelectionBoxProps) {
    return (
        <Container>
            <Label>{props.label}</Label>
            <Wrapper>
                <Input onChange={props.handleChange}>
                    {props.placeholder && <option value="">{props.placeholder}</option>}
                    {props.list.map((item: SelectOptions) => (
                        <option key={item.value} value={item.value}>
                            {item.label} ({item.value})
                        </option>
                    ))}
                </Input>
            </Wrapper>
        </Container>
    );
}

export default InputSelectionBox;
