import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Colors, Gradients, Spacing, Typography } from 'config/styling.constants';
import DefaultImage from 'assets/images/services/coming_soon_defaultpic.png';
import Button from 'components/button';
import MarketsUtils from 'utils/markets.utils';
import { IServiceOverview } from 'types';
import { Badge } from 'components/styled-components';
import ServiceUtils from 'utils/service.utils';

interface ServiceChildProps {
    image?: string;
    disabled?: boolean;
}

interface ServiceTileProps extends ServiceChildProps {
    service: IServiceOverview;
    to?: string;
}

const GridItem = styled.article`
    flex: 0 0 100%;
    margin-top: ${Spacing.xs};

    @media (min-width: 700px) {
        flex-basis: calc(50% - 10px / 2);
    }

    a:hover {
        text-decoration: none;
    }
`;

const ServiceItem = styled.div<ServiceChildProps>`
    min-height: 300px;
    display: block;
    color: ${Colors.white};
    text-decoration: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    
    background-image: url("${props => props.image || `${DefaultImage}`}");
`;

const ServiceGradient = styled.div<ServiceChildProps>`
    background-image: ${Gradients.serviceTileDefault};
    padding: ${Spacing.sm};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    .service:hover &,
    .service:focus & {
        background-image: ${props =>
            props.disabled ? `${Gradients.serviceTileDisabled}` : `${Gradients.serviceTileBlue}`};
    }
`;

const HoverContent = styled.div`
    opacity: 0;
    transform: translateY(20px);
    will-change: opacity, transform;
    transition: opacity 0.25s ease-out, transform 0.25s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .service:hover &,
    .service:focus & {
        transform: translateY(0);
        opacity: 1;
    }
`;

const Title = styled.h3`
    font-family: ${Typography.boldFontFamily};
    font-size: ${Typography.headingLevelOneFontSize};
    line-height: ${Typography.headingLevelOneLineHeight};
    letter-spacing: ${Typography.headingLevelOneLetterSpacing};
    margin-top: ${Spacing.sm};
    margin-bottom: 0;
`;

const Overview = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
`;

const Locations = styled.span`
    font-size: 14px;
    line-height: 1.36;
    letter-spacing: 0;
    flex: 1;
    margin-right: ${Spacing.xs};
`;

export default function ServiceTile({ service, to }: ServiceTileProps) {
    const upperCaseTitle = service.name ? service.name.toUpperCase() : '';
    const toLink = to || '';
    const locations = service.markets ? MarketsUtils.mapServiceTile(service.markets) : '';
    const statusBadge = ServiceUtils.isServiceActive(service) ? 'ACTIVE' : 'INACTIVE';
    return (
        <GridItem>
            <Link to={toLink} className="service">
                <ServiceItem image={service.imageUrl}>
                    <ServiceGradient>
                        <Overview>
                            <Locations>{locations}</Locations>
                            {service.subscribed && <Badge className={`service-badge active}`}>SUBSCRIBED</Badge>}
                            <Badge className={`service-badge ${statusBadge.toLowerCase()}`}>{statusBadge}</Badge>
                        </Overview>
                        <Title>{upperCaseTitle}</Title>
                        <HoverContent>
                            <p>{service.description}</p>
                            <Button outline>More Info</Button>
                        </HoverContent>
                    </ServiceGradient>
                </ServiceItem>
            </Link>
        </GridItem>
    );
}
