import React, { Component } from 'react';
import PageLayout from 'components/page.layout';
import { toastifyPromise } from 'utils/toast-utils';
import ServiceAPI from 'utils/api/services.api';
import { RouteComponentProps } from 'react-router-dom';

type TParams = { id: string };

interface ServicePrivacyState {
    dataPrivacy: string;
}

class ServicePrivacy extends Component<RouteComponentProps<TParams>, ServicePrivacyState> {
    constructor(props: RouteComponentProps<TParams>) {
        super(props);
        this.state = { dataPrivacy: '' };
    }

    componentDidMount() {
        toastifyPromise(
            ServiceAPI.getServicePolicy(Number(this.props.match.params.id)),
            'Failed to load data privacy statement',
        ).then(res => {
            this.setState({
                dataPrivacy: res.data.dataPrivacy,
            });
        });
    }
    render() {
        return (
            <PageLayout title="Data Privacy">
                <div dangerouslySetInnerHTML={{ __html: this.state.dataPrivacy }} />
            </PageLayout>
        );
    }
}

export default ServicePrivacy;
