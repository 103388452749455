import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import rootReducer from './root.reducer';
import config from 'config/config';

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk, loadingBarMiddleware(config.LOADING_BAR_CONFIG))),
);
export default store;
