import { createReducer } from 'deox';
import { combineReducers } from 'redux';
import { enableTour, fetchConfiguration } from './configuration.actions';
import { IConfigMarket } from 'types';

export const defaultConfigurationState: IConfigMarket[] = [];
export const configReducer = createReducer(defaultConfigurationState, handleAction => [
    handleAction(fetchConfiguration.complete, (_, { payload }) => payload.markets),
    handleAction(fetchConfiguration.error, state => state),
]);

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(fetchConfiguration.complete, () => defaultErrorState),
    handleAction(fetchConfiguration.error, (_, { payload }) => payload),
]);

export const defaultIsFetchingState = false;
export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
    handleAction(fetchConfiguration.next, () => true),
    handleAction([fetchConfiguration.complete, fetchConfiguration.error], () => false),
]);

export const defaultTourEnabledState = false;
export const enableTourReducer = createReducer(defaultTourEnabledState, handleAction => [
    handleAction(enableTour.handleChange, (_, { payload }) => payload),
    handleAction(enableTour.toggle, state => !state),
]);

export type ConfigurationState = ReturnType<typeof configurationReducer>;
export const configurationReducer = combineReducers({
    markets: configReducer,
    error: errorReducer,
    isFetching: isFetchingReducer,
    showTour: enableTourReducer,
});
