const USER_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';
const USER_SESSION_ATTRIBUTE_TOKEN = 'userToken';
const USER_SESSION_ATTRIBUTE_EXPIRY = 'tokenExpiry';
const USER_SESSION_ATTRIBUTE_STATE = 'state';
const USER_SESSION_ATTRIBUTE_FROM = 'fromUrl';
const USER_SESSION_ATTRIBUTE_VERIFIER = 'codeVerifier';

class LocalStorageService {
    saveSession(token: string, username: string, expires: Date) {
        localStorage.setItem(USER_SESSION_ATTRIBUTE_TOKEN, token);
        localStorage.setItem(USER_SESSION_ATTRIBUTE_NAME, username);
        localStorage.setItem(USER_SESSION_ATTRIBUTE_EXPIRY, expires.toString());
    }

    removeSession() {
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_NAME);
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_TOKEN);
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_EXPIRY);
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_VERIFIER);
    }

    getToken() {
        return localStorage.getItem(USER_SESSION_ATTRIBUTE_TOKEN);
    }

    getCodeVerifier() {
        return localStorage.getItem(USER_SESSION_ATTRIBUTE_VERIFIER);
    }

    saveCodeVerifier(verifier: string) {
        localStorage.setItem(USER_SESSION_ATTRIBUTE_VERIFIER, verifier);
    }

    getUsername() {
        return localStorage.getItem(USER_SESSION_ATTRIBUTE_NAME);
    }

    getExpiryDate(): Date | null {
        const expiry = localStorage.getItem(USER_SESSION_ATTRIBUTE_EXPIRY);
        return expiry ? new Date(expiry) : null;
    }

    saveState(state: string) {
        localStorage.setItem(USER_SESSION_ATTRIBUTE_STATE, state);
        return state;
    }

    getState() {
        return localStorage.getItem(USER_SESSION_ATTRIBUTE_STATE);
    }

    saveFrom(from?: string) {
        from && localStorage.setItem(USER_SESSION_ATTRIBUTE_FROM, from);
    }

    getFrom() {
        return localStorage.getItem(USER_SESSION_ATTRIBUTE_FROM);
    }

    removeFrom() {
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_FROM);
    }
}

export default new LocalStorageService();
