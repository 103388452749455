import React, { ChangeEvent, Fragment, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import PageLayout from 'components/page.layout';
import { RootStore } from 'types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Col } from 'react-bootstrap';
import { loginAction } from '../../redux/session/session.actions';
import { AnyAction } from 'deox';
import { selectSessionExpiry, selectSessionToken } from '../../redux/session/session.selector';
import { Link } from 'react-router-dom';

interface PlatfromTermsPageProps {
    login: (token: string, expiry: Date, allowEmails: boolean, termsAccepted: boolean) => Promise<AnyAction>;
    token: string | null;
    expiry: Date | null;
}

export const PlatformTermsPage: React.FC<PlatfromTermsPageProps> = ({ login, token, expiry }) => {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [privacyPolicyAccepted, setPrivacyAccepted] = useState(false);
    const [allowEmails, setAllowEmails] = useState(false);

    const accept = useCallback(() => token && expiry && login(token, expiry, allowEmails, true), [
        login,
        token,
        expiry,
        allowEmails,
    ]);

    const handleTermsAccepted = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => setTermsAccepted(event.target.checked),
        [setTermsAccepted],
    );

    const handlePrivacyAccepted = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => setPrivacyAccepted(event.target.checked),
        [setPrivacyAccepted],
    );

    const handleEmailsAllowed = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => setAllowEmails(event.target.checked),
        [setAllowEmails],
    );

    return (
        <Fragment>
            <PageLayout paddingTop="2em">
                <h1>Welcome to BMW Group Labs</h1>
                <p>You have to accept our terms and conditions and privacy policy to use BMW Group labs.</p>
                <Form.Row>
                    <Col sm={7}>
                        <Form.Row>
                            <Form.Group controlId="termsAccepted">
                                <Form.Check
                                    onChange={handleTermsAccepted}
                                    name="termsAccepted"
                                    type="checkbox"
                                    checked={termsAccepted}
                                    label="I agree to the"
                                />
                            </Form.Group>
                            <Link to="/terms" target="_blank" style={{ marginLeft: '5px' }}>
                                terms and conditions
                            </Link>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group controlId="privacyPolicyAccepted">
                                <Form.Check
                                    onChange={handlePrivacyAccepted}
                                    name="privacyPolicyAccepted"
                                    type="checkbox"
                                    checked={privacyPolicyAccepted}
                                    label="I agree to the"
                                />
                            </Form.Group>
                            <Link to="/dataPrivacy" target="_blank" style={{ marginLeft: '5px' }}>
                                privacy policy
                            </Link>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group controlId="allowEmails">
                                <Form.Check
                                    onChange={handleEmailsAllowed}
                                    name="allowEmails"
                                    type="checkbox"
                                    checked={allowEmails}
                                    label="I agree to get e-mail notifications"
                                />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                </Form.Row>
                <Button onClick={accept} disabled={!privacyPolicyAccepted || !termsAccepted}>
                    Register
                </Button>
            </PageLayout>
        </Fragment>
    );
};

const mapStateToProps = (state: RootStore) => ({
    expiry: selectSessionExpiry(state),
    token: selectSessionToken(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => ({
    login: (token: string, expiry: Date, allowEmails: boolean, termsAccepted: boolean) =>
        dispatch(loginAction(token, expiry, allowEmails, termsAccepted)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PlatformTermsPage);
