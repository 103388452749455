import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import * as serviceWorker from './utils/serviceWorker';
import apiClient from 'utils/api/api-client';
// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
// Front Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCar, faCheck, faSortDown, faTrashAlt, faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { logoutAction } from 'redux/session/session.actions';
import LocalStorageService from 'utils/LocalStorageService';

library.add(faTrashAlt, faCheck, faUserFriends, faUser, faCar, faSortDown);

const rootElement = document.getElementById('root');
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

apiClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            LocalStorageService.removeSession();
            store.dispatch(logoutAction.complete());
        }
        return error;
    },
);
