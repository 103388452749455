import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import PageLayout from 'components/page.layout';
import ServiceUtils from 'utils/service.utils';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { IService, IVehicle, RootStore, SubscriptionActionType } from 'types';
import { connect } from 'react-redux';
import { selectSelectedVehicle } from 'redux/vehicle-list/vehicle-list.selector';
import BroadcastMessageForm from './message.form';
import { ThunkDispatch } from 'redux-thunk';
import { loadServiceDetails, unsubscribeAction } from 'redux/serviceDetails/service-details.actions';
import { selectServicesDetails } from 'redux/serviceDetails/service-details.selector';
import ServiceHeader from 'components/header/header-service';
import SubscriptionComponent from './subscription.buttons';
import RequirementsSection from 'components/blocks/requirements-section';
import SectionImage from 'assets/images/services/BMW_building.jpg';
import { REDIRECT_URI } from 'config/constants';
import { ServiceContentSections } from 'components/blocks/content-sections-section';

type TParams = { id: string };

interface ServiceDetailsProps extends RouteComponentProps<TParams> {
    selectedVehicle: IVehicle | null;
    service?: IService;
    loadServiceDetails: (serviceId: number) => void;
    unsubscribeAction: (serviceId: number) => void;
}

interface ServiceDetailsState {
    serviceId: number;
}

class ServiceDetails extends React.Component<ServiceDetailsProps, ServiceDetailsState> {
    constructor(props: ServiceDetailsProps) {
        super(props);
        const serviceId = Number(this.props.match.params.id);
        this.state = { serviceId };

        this.subscribeAction = this.subscribeAction.bind(this);
    }

    componentDidMount() {
        this.props.loadServiceDetails(this.state.serviceId);
    }

    componentDidUpdate(prevProps: ServiceDetailsProps) {
        // Reload service details if selected vehicle changed
        if (this.props.selectedVehicle !== prevProps.selectedVehicle) {
            this.props.loadServiceDetails(this.state.serviceId);
        }
    }

    subscribeAction = (action: SubscriptionActionType) => {
        if (action === 'SUBSCRIBE') {
            this.props.history.push(`/services/${this.state.serviceId}/subscribe`);
        } else {
            this.props.unsubscribeAction(this.state.serviceId);
        }
    };

    render() {
        const service = this.props.service || ServiceUtils.createInitialService(this.state.serviceId);
        const serviceId = this.state.serviceId;
        const title = `${service.content.headline || ''}`;
        const vehicle = this.props.selectedVehicle
            ? `${this.props.selectedVehicle.vin} (${this.props.selectedVehicle.model})`
            : undefined;

        return (
            <Fragment>
                <ServiceHeader title={title} image={service.content.headerImage} subtitle={service.content.subline}>
                    <SubscriptionComponent
                        serviceId={service.id}
                        matchRequirements={service.matchRequirements}
                        isSubscribed={service.subscribed}
                        isActive={ServiceUtils.isServiceActive(service)}
                        subscribeAction={this.subscribeAction}
                        vehicle={vehicle}
                        maxSubscriptionCount={service.maxSubscriptionCount}
                        subscribedUsersCount={service.subscribedUsersCount}
                    />
                </ServiceHeader>
                <PageLayout>
                    <Tabs defaultActiveKey="info" id="tab">
                        <Tab eventKey="info" title="Info">
                            {service.subscribed && (
                                <BroadcastMessageForm key={this.state.serviceId} serviceId={this.state.serviceId} />
                            )}
                            <RequirementsSection
                                title="TEST USER REQUIREMENTS"
                                preconditions={service.content.preconditions}
                                serviceEmail={service.serviceEmail}
                                serviceName={service.name}
                            />
                        </Tab>
                        {service.content.sections.length > 0 && (
                            <Tab eventKey="description" title="Description">
                                <ServiceContentSections
                                    sections={service.content.sections}
                                    isServiceSubscribed={service.subscribed}
                                    selectedVehicle={this.props.selectedVehicle}
                                />
                            </Tab>
                        )}
                        {service.subscribed && service.instruction && service.instruction.sections.length > 0 && (
                            <Tab eventKey="instruction" title="Instructions">
                                <ServiceContentSections
                                    sections={service.instruction.sections}
                                    isServiceSubscribed={service.subscribed}
                                    selectedVehicle={this.props.selectedVehicle}
                                />
                            </Tab>
                        )}
                        <Tab eventKey="legal documents" title="Legal documents">
                            <PageLayout title="Legal Documents" headerImage={SectionImage}>
                                <h3>Terms & Conditions and Data Privacy of {service.name}</h3>
                                <br />
                                <a href={`${REDIRECT_URI}/services/${serviceId}/terms`}>Terms & Conditions</a>
                                <br />
                                <a href={`${REDIRECT_URI}/services/${serviceId}/privacy`}>Data Privacy Statement</a>
                                <br />
                                <br />
                                In case of any queries please contact us via
                                <a href={`mailto:${service.serviceEmail}?subject=Support Request for ${service.name}`}>
                                    {' '}
                                    Email
                                </a>
                            </PageLayout>
                        </Tab>
                    </Tabs>
                </PageLayout>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    selectedVehicle: selectSelectedVehicle(state),
    service: selectServicesDetails(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadServiceDetails: (serviceId: number) => dispatch(loadServiceDetails(serviceId)),
    unsubscribeAction: (serviceId: number) => dispatch(unsubscribeAction(serviceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
