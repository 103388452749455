import { ThunkDispatch } from 'redux-thunk';
import LocalStorageService from 'utils/LocalStorageService';
import { createActionCreator } from 'deox';
import { LOGOUT_URL } from '../../config/constants';
import UserAPI from '../../utils/api/user.api';
import { ErrorResponse } from '../../utils/toast-utils';

function _loginActionThunk(token: string, expiryDate: Date, allowEmails: boolean, termsAccepted: boolean) {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        dispatch(loginAction.next());

        const completeLogin = async (email: string) => {
            LocalStorageService.saveSession(token, email, expiryDate);
            if (email && allowEmails) {
                await UserAPI.updateEmailSetting(allowEmails);
            }

            return dispatch(loginAction.complete(token, email, expiryDate));
        };

        try {
            const response = await UserAPI.getProfile(token, termsAccepted);

            if (response.status === 200) {
                const { email } = response.data;
                return completeLogin(email);
            } else if (response.status === 428) {
                return completeLogin('');
            } else {
                LocalStorageService.removeSession();
                return dispatch(loginAction.error({ response }));
            }
        } catch (error) {
            LocalStorageService.removeSession();
            return dispatch(loginAction.error(error));
        }
    };
}

export const loginAction = Object.assign(_loginActionThunk, {
    next: createActionCreator('FETCH_LOGIN_NEXT'),
    complete: createActionCreator(
        'FETCH_LOGIN_REQUEST_COMPLETE',
        resolve => (token: string, username: string, expiryDate: Date) => resolve({ token, username, expiryDate }),
    ),
    error: createActionCreator('FETCH_LOGIN_REQUEST_ERROR', resolve => (error: ErrorResponse) => resolve(error)),
});

function _logoutActionThunk() {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        await dispatch(logoutAction.next());

        LocalStorageService.removeSession();
        LocalStorageService.removeFrom();
        await dispatch(logoutAction.complete());
        localStorage.removeItem('state');

        // // Redirect to login page
        window.location.replace(LOGOUT_URL);
    };
}

export const logoutAction = Object.assign(_logoutActionThunk, {
    next: createActionCreator('LOGOUT_ACTION'),
    complete: createActionCreator('LOGOUT_COMPLETE'),
});
