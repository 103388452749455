import React, { useEffect, useRef } from 'react';
import { StyledLink } from 'components/styled-components';
import SectionBlock from 'components/blocks/showcase-section';
import { IVehicle, ServiceSection } from 'types';
import styled from 'styled-components';
import LocalStorageService from 'utils/LocalStorageService';
import { CUSTOM_SERVICE_CONTENT_DOMAIN } from 'config/constants';

export interface ContentSectionsProps {
    readonly sections: ServiceSection[];
    readonly isServiceSubscribed: boolean | undefined;
    readonly selectedVehicle: IVehicle | null;
}

interface CustomSectionContentProps {
    readonly contentUrl: string;
    readonly subscribedVin?: string;
}

interface CustomContentConfiguration {
    readonly accessToken: string;
    readonly subscribedVIN?: string;
}

export function ServiceContentSections({ sections, isServiceSubscribed, selectedVehicle }: ContentSectionsProps) {
    return (
        <>
            {sections.map((section, index) => (
                <SectionBlockItem
                    index={index}
                    section={section}
                    key={section.id}
                    subscribedVin={isServiceSubscribed ? selectedVehicle?.vin : undefined}
                />
            ))}
        </>
    );
}

const contentHandler = (content: string) => {
    const regex = /<([^>]+)>/g;
    const matches = content.match(regex);

    if (matches) {
        const extractedLinks = matches.map(match => match.slice(1, -1));

        const paragraphs = content.split(regex);

        return (
            <>
                {paragraphs.map((paragraph, index) => (
                    <React.Fragment key={index}>
                        {extractedLinks.includes(paragraph) && (
                            <StyledLink key={index} href={paragraph} target="_blank" rel="noopener noreferrer">
                                <p>{paragraph}</p>
                            </StyledLink>
                        )}
                        {!extractedLinks.includes(paragraph) && <p>{paragraph}</p>}
                    </React.Fragment>
                ))}
            </>
        );
    } else {
        return content;
    }
};

const SectionBlockItem: React.FC<{ index: number; section: ServiceSection; subscribedVin: string | undefined }> = ({
    index,
    section,
    subscribedVin,
}) => (
    <SectionBlock
        key={index}
        contentType={section.contentType}
        contentUrl={section.contentUrl}
        swap={index % 2 === 0}
        greyBackground={index === 0}
    >
        {section.contentType === 'CUSTOM' ? (
            <CustomContentSection subscribedVin={subscribedVin} contentUrl={section.contentUrl} />
        ) : (
            <>
                <h2>{section.title}</h2>
                <div style={{ whiteSpace: 'pre-line' }}>{contentHandler(section.content)}</div>
            </>
        )}
    </SectionBlock>
);

const CustomContent = styled.iframe`
    border: 0px;
`;

function CustomContentSection({ subscribedVin, contentUrl }: CustomSectionContentProps) {
    const ref = useRef<HTMLIFrameElement>(null);
    const contentWindow = ref?.current?.contentWindow;
    const height = 600; //TODO: temporary solution. Should be reworked to automatic iframe sizing or specifying height in admin frontend

    useEffect(
        function() {
            const setContent = async function setContent() {
                const token = LocalStorageService.getToken();
                if (!token) {
                    return;
                }
                const configuration: CustomContentConfiguration = {
                    accessToken: token,
                    subscribedVIN: subscribedVin,
                };
                await new Promise(resolve => setTimeout(resolve, 10)); // delay required to work in Safari
                contentWindow?.postMessage(configuration, CUSTOM_SERVICE_CONTENT_DOMAIN);
            };
            setContent();
        },
        [subscribedVin, contentUrl, contentWindow],
    );

    return (
        <CustomContent
            src={contentUrl}
            ref={ref}
            width="100%"
            height={`${height}px`}
            sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-scripts"
            scrolling="no"
        />
    );
}
