import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
// **** Pages ****
import Dashboard from 'pages/dashboard/dashboard.page';
import Mailbox from 'pages/mailbox/mailbox.page';
import Faq from 'pages/static-pages/faq';
import Imprint from 'pages/static-pages/imprint';
import Terms from 'pages/static-pages/terms';
import DataPrivacy from 'pages/static-pages/dataPrivacy';
import Cookies from 'pages/static-pages/cookies';
import Home from 'pages/home/home.page';
import HomeServiceDetail from 'pages/home-service-detail/service-details.page';
import HomeServiceOverview from 'pages/home-service/home-service.page';
import ServicesPage from 'pages/services/overview/page';
import ServiceDetails from 'pages/services/details/details';
import ServiceTerms from 'pages/static-pages/serviceTerms';
import ServicePrivacy from 'pages/static-pages/servicePrivacy';
import SettingsPage from './pages/settings/settings-page';
import AcceptTermsPage from './pages/services/terms-page/terms.page';

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/home" component={Home} />
            <Route path="/faq" component={Faq} />
            <Route path="/imprint" component={Imprint} />
            <Route path="/terms" component={Terms} />
            <Route path="/dataPrivacy" component={DataPrivacy} />
            <Route path="/cookies" component={Cookies} />
            <Route path="/services-overview/:id" component={HomeServiceDetail} />
            <Route path="/services-overview" component={HomeServiceOverview} />
            <AuthenticatedRoute path="/mailbox" component={Mailbox} />
            <AuthenticatedRoute path="/dashboard" component={Dashboard} />
            <Route path="/services/:id/terms" component={ServiceTerms} />
            <Route path="/services/:id/privacy" component={ServicePrivacy} />
            <AuthenticatedRoute path="/services/:id/subscribe" component={AcceptTermsPage} />
            <AuthenticatedRoute path="/services/:id" component={ServiceDetails} />
            <AuthenticatedRoute path="/services" component={ServicesPage} />
            <AuthenticatedRoute path="/settings" component={SettingsPage} />
        </Switch>
    );
};
export default Routes;
