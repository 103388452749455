import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import WatermarkBlock from 'components/watermark';
import MessageAPI from 'utils/api/messages.api';
import { toastifyPromise } from '../../../utils/toast-utils';

const SectionContainer = styled.section`
    background-color: #f2f2f2;
    margin-bottom: 1em;
`;

const LeftBlock = styled.div`
    margin: 0 0 10px;

    @media (min-width: 840px) {
        flex: 1;
    }
`;

const RightBlock = styled.div`
    margin: 0;
    @media (min-width: 840px) {
        flex: 2;
        margin: 0 0 0 40px;
    }
`;

const Row = styled.div`
    @media (min-width: 840px) {
        display: flex;
    }
`;

export default function FeedbackMessageForm(props: { serviceId: number }) {
    const initialValues = {
        serviceId: props.serviceId,
        message: '',
    };

    return (
        <SectionContainer>
            <WatermarkBlock watermark="white" position="bottom-left" padding="md">
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        toastifyPromise(
                            MessageAPI.postMessage(props.serviceId, values),
                            'Failed to submit feedback',
                            'Feedback submitted',
                        )
                            .then(() => resetForm())
                            .finally(() => setSubmitting(false));
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <LeftBlock>
                                    <h3>Feedback?</h3>
                                    <p>
                                        If you have something to say about this service, we are looking forward to your
                                        comments. Your feedback is an essential part of the development process and is
                                        greatly appreciated.
                                    </p>
                                </LeftBlock>
                                <RightBlock>
                                    <Form.Group>
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="message"
                                            placeholder="Enter your feedback"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.message}
                                        />
                                    </Form.Group>

                                    <Button variant="primary" type="submit" disabled={isSubmitting || !values.message}>
                                        Submit Feedback
                                    </Button>
                                </RightBlock>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </WatermarkBlock>
        </SectionContainer>
    );
}
