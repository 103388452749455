import { ServiceSurveyResponse } from './../../types/service.types';
import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';
import { IService, IServiceOverview, ServicePolicy, SubscribeActionPayload } from 'types';

export class ServiceAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    private static createHeader(vin: string | undefined) {
        if (vin) {
            return { Vehicle: vin };
        } else {
            return {};
        }
    }

    getServices(vin?: string) {
        const headers = ServiceAPI.createHeader(vin);

        return this.client
            .get<IServiceOverview[]>(`/services`, { headers })
            .then(response => response.data);
    }

    getPublicServices() {
        return this.client.get<IServiceOverview[]>(`/public/services`).then(response => response.data);
    }

    getPublicServicesDetails(serviceId: number) {
        return this.client.get(`/public/services/${serviceId}`).then(response => response.data);
    }

    getServiceDetails(serviceId: number, vin?: string) {
        const headers = ServiceAPI.createHeader(vin);

        return this.client
            .get<IService>(`/services/${serviceId}`, { headers })
            .then(response => response.data);
    }

    subscribe(serviceId: number, vin?: string, payload?: SubscribeActionPayload) {
        const headers = ServiceAPI.createHeader(vin);

        return this.client.post(`/services/${serviceId}/subscribe`, payload, { headers });
    }

    unsubscribe(serviceId: number, vin?: string) {
        const headers = ServiceAPI.createHeader(vin);

        return this.client.delete(`/services/${serviceId}/subscribe`, { headers });
    }

    getServicePolicy(serviceId: number) {
        return this.client.get<ServicePolicy>(`/services/${serviceId}/policy`);
    }

    getServiceSurvey(serviceId: number) {
        return this.client.get(`/services/${serviceId}/survey`);
    }

    putServiceSurvey(serviceId: number, surveyResponses: ServiceSurveyResponse[], vin?: string) {
        const headers = ServiceAPI.createHeader(vin);
        return this.client.put(`/services/${serviceId}/survey/responses`, surveyResponses, { headers });
    }
}

export default new ServiceAPI(Client);
