import { IConfigMarket, RootStore } from 'types';

export function selectConfigurationMarkets(state: RootStore): IConfigMarket[] {
    return state.configuration.markets;
}

export function selectConfigurationError(state: RootStore): string {
    return state.configuration.error;
}

export function selectConfigurationShowTour(state: RootStore): boolean {
    return state.configuration.showTour;
}
