import packageJson from '../../package.json';

export const SELECTABLE_MARKETS = [
    { locale: 'de', name: 'Germany' },
    { locale: 'uk', name: 'England (UK)' },
    { locale: 'nl', name: 'Netherlands' },
];

export const PUBLIC_NAVIGATION = [
    { href: '/home', name: 'Home' },
    { href: '/services-overview', name: 'Services' },
    { href: '/faq', name: 'FAQ' },
];

export const LOGIN_NAVIGATION = [
    { href: '/home', name: 'Home' },
    { href: '/dashboard', name: 'Dashboard' },
    { href: '/services', name: 'Services' },
    { href: '/mailbox', name: 'Mailbox' },
];

export const LOGIN_MOBILE_NAVIGATION = [
    { href: '/home', name: 'Home' },
    { href: '/dashboard', name: 'Dashboard' },
    { href: '/services', name: 'Services' },
    { href: '/mailbox', name: 'Mailbox' },
    { href: '/settings', name: 'Settings' },
];

export const FOOTER_ITEMS = [
    { href: '/faq', name: 'FAQ' },
    { href: '/terms', name: 'Terms & Conditions' },
    { href: '/dataPrivacy', name: 'Data Privacy' },
    { href: '/imprint', name: 'Imprint' },
    { href: '/cookies', name: 'Cookies' },
];

export const ADMIN_CHANNEL_ID = -1;

export const DEFAULT_FROM = '/dashboard';

const OAUTH_ORIGIN = process.env.REACT_APP_OAUTH_ORIGIN || 'https://customer-i.bmwgroup.com';

export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || window.location.origin;
export const CUSTOM_SERVICE_CONTENT_DOMAIN = process.env.REACT_APP_CUSTOM_CONTENT_DOMAIN || '';

export const CLIENT = process.env.REACT_APP_CLIENT || 'bmwlabs';
export const LOGIN_URL = `${OAUTH_ORIGIN}/oneid/#/login?client=${CLIENT}&country=DE&language=en&scope=authenticate_user%20vehicle_data%20remote_services%20svds&redirect_uri=${REDIRECT_URI}&response_type=code`;
export const LOGOUT_URL = `${OAUTH_ORIGIN}/oneid/logout.html?redirectUrl=${REDIRECT_URI}`;

export type BmwEnvironment = 'LOCAL' | 'INT' | 'PROD';
export const BMW_ENVIRONMENT = (process.env.REACT_APP_BMW_ENV as BmwEnvironment) || 'LOCAL';
export const APP_VERSION = packageJson.version;
