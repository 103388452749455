import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';

export class ConfigurationAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    getConfig() {
        return this.client.get(`/public/config.json`);
    }
}
export default new ConfigurationAPI(Client);
