import React, { Component } from 'react';
import PageLayout from 'components/page.layout';
import { toastifyPromise } from 'utils/toast-utils';
import ServiceAPI from 'utils/api/services.api';
import { RouteComponentProps } from 'react-router-dom';

type TParams = { id: string };

interface ServiceTermsState {
    termsAndConditions: string;
}

class ServiceTerms extends Component<RouteComponentProps<TParams>, ServiceTermsState> {
    constructor(props: RouteComponentProps<TParams>) {
        super(props);
        this.state = { termsAndConditions: '' };
    }

    componentDidMount() {
        toastifyPromise(
            ServiceAPI.getServicePolicy(Number(this.props.match.params.id)),
            'Failed to load data privacy statement',
        ).then(res => {
            this.setState({
                termsAndConditions: res.data.termsAndConditions,
            });
        });
    }
    render() {
        return (
            <PageLayout title="Terms And Conditions">
                <div dangerouslySetInnerHTML={{ __html: this.state.termsAndConditions }} />
            </PageLayout>
        );
    }
}

export default ServiceTerms;
