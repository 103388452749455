import React from 'react';
import ServiceGrid from '../../components/services-grid/services.grid';
import PageLayout from 'components/page.layout';
import HeaderImage from 'assets/images/services/BMW_building.jpg';

const HomeServiceOverview = () => {
    return (
        <PageLayout title="Public Services" headerImage={HeaderImage}>
            <ServiceGrid />
        </PageLayout>
    );
};
export default HomeServiceOverview;
