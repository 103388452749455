import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';
import { BearerToken, GetTokenPayload } from 'types';

export class TokenAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    getToken(payload: GetTokenPayload) {
        const headers = {};

        return this.client.post<BearerToken>(`/authV2/token`, payload, { headers });
    }
}

export default new TokenAPI(Client);
