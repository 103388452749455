import React, { Fragment } from 'react';
import styled from 'styled-components';
import ChatConversationItem from '../../components/mailbox/conversation-item';
import { IConversationOverview } from 'types';

// noinspection CssUnknownProperty
const ConversationContainer = styled.div`
    overflow-x: hidden;
    height: auto;
    max-height: 60vh;
    overflow-anchor: auto;
`;

interface ConversationBoxProps {
    conversations: IConversationOverview[];
    selectedIndex?: number;
    selectConversation: (index: number) => void;
}

class ConversationBox extends React.Component<ConversationBoxProps> {
    render() {
        const { conversations, selectedIndex, selectConversation } = this.props;

        return (
            <Fragment>
                <ConversationContainer id="conversation-box">
                    {conversations.map((item, index) => (
                        <ChatConversationItem
                            key={index}
                            selected={selectedIndex === index}
                            messageItem={item}
                            onClick={() => selectConversation(index)}
                        />
                    ))}
                    {conversations.length === 0 && <div>No messages</div>}
                </ConversationContainer>
            </Fragment>
        );
    }
}
export default ConversationBox;
