import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from 'components/navigation/navigation';
import ScrollToTop from 'components/scroll-to-top';
import Config from 'config/config';
import AppStartComponent from 'components/app-start-component';
import Routes from 'routes';
import { Slide, ToastContainer } from 'react-toastify';
import TourComponent from 'components/tour-component';
import Footer from './components/footer/footer';
import LoginHandler from './LoginHandler';
import LogoutWarning from './LogoutWarning';

const App: React.FC = () => {
    return (
        <Router basename={Config.BASE_ROUTER_PATH}>
            <AppStartComponent />

            <ScrollToTop>
                <Navigation />
                <Routes />
                <Footer />
            </ScrollToTop>

            <ToastContainer position="bottom-right" hideProgressBar={true} transition={Slide} />
            <LoginHandler />
            <LogoutWarning />
            <TourComponent />
        </Router>
    );
};

export default App;
