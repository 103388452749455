import { IService, IServiceOverview, ServicePreconditions } from 'types';

class ServiceUtils {
    static createInitialService = (id: number): IService => {
        const service = ServiceUtils.createEmptyService();
        service.id = id;
        return service;
    };

    static createEmptyService = (): IService => {
        return {
            id: 0,
            name: '',
            description: '',
            imageUrl: '',
            content: {
                headline: '',
                subline: '',
                headerImage: '',
                sections: [],
                preconditions: ServiceUtils.createEmptyPreconditions(),
            },
            instruction: {
                headline: '',
                subline: '',
                headerImage: '',
                sections: [],
            },
            requirements: {
                markets: [],
                hmiVersions: [],
            },
            subscribedUsersCount: 0,
            active: false,
            hidden: true,
            maxSubscriptionCount: null,
        };
    };

    static isServiceActive = (service: IService | IServiceOverview) => {
        if (service.maxSubscriptionCount !== null) {
            const availableSeats = service.maxSubscriptionCount - service.subscribedUsersCount;
            return service.active && (!service.maxSubscriptionCount || availableSeats > 0);
        }
        return service.active;
    };

    static createEmptyPreconditions = (): ServicePreconditions => {
        return {
            vehiclePreconditions: [''],
            additionalPreconditions: [''],
            markets: [],
        };
    };

    static createEmptyServiceOverview = (): IServiceOverview => {
        return {
            id: 0,
            name: '',
            description: '',
            active: false,
            hidden: true,
            subscribed: false,
            markets: [],
            subscribedUsersCount: 0,
            matchRequirements: false,
            maxSubscriptionCount: 0,
        };
    };
}

export default ServiceUtils;
