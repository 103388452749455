import React, { ReactChild, useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOnclickOutside from 'use-onclickoutside';

export interface DropdownWrapperProps {
    children?: ReactChild;
    title?: string;
    icon?: ReactChild;
    id?: string;
}
interface DropdownContainerProps {
    navWidth: number;
    dropdownWidth: number;
}

const DropdownWrapper = styled.li`
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 0;
    margin-right: ${Spacing.sm};

    @media (max-width: 700px) {
        margin-top: 80px;
    }

    @media screen and (min-width: 700px) and (max-width: 1060px) {
        margin-top: 80px;
    }
`;

const NavLink = styled.div`
    color: ${Colors.darkGrey};
    min-width: 60px;
    font-weight: bold;
    text-decoration: none;
    font-size: ${Typography.headingLevelTwoFontSize};
    padding-bottom: calc(10px / 3);

    font-size: inherit;

    :hover::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0;
        left: 0;
        bottom: 0;
        z-index: 0.2;
        border-bottom: 2px solid ${Colors.darkGrey};
    }
`;

const DropdownContainer = styled.div<DropdownContainerProps>`
    position: absolute;
    min-width: 180px;
    left: ${props => `${props.navWidth / 2 - props.dropdownWidth / 2}px`};
    z-index: 2;
    margin-left: 10px;
`;

const DropdownContent = styled.div`
    background-color: ${Colors.white};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 0;
`;

const Arrow = styled.div`
    position: relative;
    width: 0;
    height: 0;
    z-index: 1;
    margin: 0 auto;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
`;

function DropdownComponent(props: DropdownWrapperProps) {
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const [navWidth, setNavWidth] = useState(0);
    const [dropdownWidth, setDropdownWidth] = useState(0);

    // https://github.com/Andarist/use-onclickoutside
    // https://usehooks.com/useOnClickOutside/
    useOnclickOutside(ref, () => setOpen(false));

    return (
        <DropdownWrapper ref={ref}>
            <NavLink
                ref={ref => {
                    if (ref) {
                        setNavWidth(ref.getBoundingClientRect().width);
                    }
                }}
                onClick={() => setOpen(!open)}
                aria-controls="dropdown-component"
                aria-expanded={open}
                id={props.id}
            >
                {props.icon && props.icon}
                {props.title}
                <FontAwesomeIcon
                    icon="sort-down"
                    color="grey"
                    style={{ marginLeft: '5px', paddingBottom: '2px', fontSize: '1.4em', height: '19px' }}
                />
            </NavLink>

            {open && (
                <DropdownContainer
                    id="dropdown-component"
                    ref={ref => {
                        if (ref) {
                            setDropdownWidth(ref.getBoundingClientRect().width);
                        }
                    }}
                    navWidth={navWidth}
                    dropdownWidth={dropdownWidth}
                >
                    <Arrow />
                    <DropdownContent>{props.children}</DropdownContent>
                </DropdownContainer>
            )}
        </DropdownWrapper>
    );
}
export default DropdownComponent;
