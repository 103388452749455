import React from 'react';
import PageLayout from 'components/page.layout';

export default function Imprint() {
    return (
        <PageLayout title="Imprint">
            <section>
                <p>
                    This website is maintained by Bayerische Motoren Werke Aktiengesellschaft (Petuelring 130, 80788
                    München).
                </p>
                <p>
                    Contact: labs@bmwgroup.com
                    <br />
                    Phone: +49 89 382-0
                    <br />
                    Monday - Friday, between 8:00 AM and 5:00 PM.
                    <br />
                    Fax: +49 89-382-70-25858
                    <br />
                    The legal representatives of BMW AG are the members of the Board of Management:
                    <br />
                    Oliver Zipse (Chairman), Ilka Horstmeier, Milan Nedeljković, Jochen Goller, Walter Mertl, Joachim
                    Post, Frank Weber.
                </p>
                <p>Chairman of the Supervisory Board: Norbert Reithofer</p>
                <p>Domicile and Court of Registry: München HRB 42243</p>
                <p>Value-added tax identification no.: DE129273398</p>
            </section>
        </PageLayout>
    );
}
