import React, { ChangeEvent } from 'react';
import InputSelectionBox from 'components/inputSelectionBox';
import styled from 'styled-components';
import InputBox from 'components/inputBox';
import { Spacing, Typography } from 'config/styling.constants';
import { IConfigMarket, RootStore } from 'types';
import { selectConfigurationMarkets } from 'redux/configuration/configuration.selector';
import { connect } from 'react-redux';
import marketsUtils from 'utils/markets.utils';

const Container = styled.div`
    margin: 20px 0;
`;

const Row = styled.div`
    @media (min-width: 640px) {
        display: flex;
    }
`;

const Left = styled.div`
    flex: 1;
`;

const Right = styled.div`
    flex: 1;
    margin-top: ${Spacing.sm};
    @media (min-width: 640px) {
        margin-top: 0;
        padding-left: 10px;
    }
`;

const Checkbox = styled.div`
    display: flex;
    align-items: center;
    font-family: ${Typography.boldFontFamily};
`;

type FILTER_TYPE = 'MARKETS' | 'NAME' | 'ACTIVE';

interface ServiceFilterProps {
    handleChange: (event: ChangeEvent, filter: FILTER_TYPE) => void;
    markets: IConfigMarket[];
}

export function ServiceFilter(props: ServiceFilterProps) {
    return (
        <Container>
            <Row>
                <Left>
                    <InputSelectionBox
                        label="Filter services by country"
                        placeholder="No country selected"
                        list={marketsUtils.getMarketOptions()}
                        handleChange={e => props.handleChange(e, 'MARKETS')}
                    />
                </Left>
                <Right>
                    <InputBox
                        label="Filter services by name"
                        placeholder="Enter a service name"
                        handleChange={e => props.handleChange(e, 'NAME')}
                    />
                </Right>
            </Row>
            <Checkbox>
                <input type="checkbox" onChange={e => props.handleChange(e, 'ACTIVE')} />
                <p style={{ marginLeft: '10px' }}>Filter by active status</p>
            </Checkbox>
        </Container>
    );
}

const mapStateToProps = (state: RootStore) => ({
    markets: selectConfigurationMarkets(state),
});

export default connect(mapStateToProps)(ServiceFilter);
