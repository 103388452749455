import React from 'react';
import PageLayout from '../../components/page.layout';
import styled from 'styled-components';
import DashboardServiceGrid from './services.grid';
import DashboardMessagesGrid from './messages.grid';
import FeedbackSections from './feedback-section';
import { Title } from 'components/styled-components';
import { Spacing } from 'config/styling.constants';
import MarketingSection from '../../components/marketing/marketing-section';

const DashboardLayout = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 960px) {
        flex-direction: row;
    }
`;

const Left = styled.div`
    @media (min-width: 640px) {
        padding-right: ${Spacing.md};
        flex: 2;
    }
`;

const Right = styled.div`
    @media (min-width: 640px) {
        flex: 1;
    }
`;

const DashboardPage = () => {
    return (
        <PageLayout paddingTop="2em">
            <DashboardLayout>
                <Left>
                    <Title marginBottom={Spacing.sm}>Your Services</Title>
                    <DashboardServiceGrid />
                    <DashboardMessagesGrid />
                </Left>
                <Right>
                    <Title marginBottom={Spacing.sm}>News</Title>
                    <MarketingSection />
                    <FeedbackSections />
                </Right>
            </DashboardLayout>
        </PageLayout>
    );
};
export default DashboardPage;
