import { RootStore } from 'types';
import { ErrorResponse } from '../../utils/toast-utils';

export function selectSessionIsAuthenticated(state: RootStore): boolean {
    return !!state.session.token && state.session.expiryDate != null && state.session.expiryDate > new Date();
}

export function selectSessionUsername(state: RootStore): string | null {
    return state.session.username;
}

export function selectSessionExpiry(state: RootStore): Date | null {
    return state.session.expiryDate;
}

export function selectSessionToken(state: RootStore): string | null {
    return state.session.token;
}

export function selectSessionError(state: RootStore): ErrorResponse {
    return state.session.error;
}

export function selectTermsAccepted(state: RootStore): boolean {
    // if the username is not empty then we know that the user is stored in the backend and that he must have accepted the terms before
    return state.session.username != null && state.session.username.length > 0;
}
