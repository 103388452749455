import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import PageLayout from '../../components/page.layout';
import { Title } from '../../components/styled-components';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { RootStore } from '../../types';
import { selectSessionToken } from '../../redux/session/session.selector';
import { toastifyPromise } from '../../utils/toast-utils';
import UserAPI from '../../utils/api/user.api';

interface SettingsPageProps {
    token: string | null;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ token }) => {
    const [allowEmails, setAllowEmails] = useState(false);

    const updateEmailSetting = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const allowEmailsChecked = event.target.checked;
            setAllowEmails(allowEmailsChecked);
            toastifyPromise(
                UserAPI.updateEmailSetting(allowEmailsChecked)
                    .then(({ allowEmailNotifications }) => setAllowEmails(allowEmailNotifications))
                    .catch(e => {
                        setAllowEmails(!allowEmailsChecked);
                        return Promise.reject(e);
                    }),
                'Failed to update e-mail setting',
                allowEmailsChecked ? 'Subscribed to e-mails' : 'Unsubscribed from e-mails',
            );
        },
        [setAllowEmails],
    );

    // Fetch state from server
    useEffect(() => {
        token &&
            toastifyPromise(
                UserAPI.getProfile(token, false).then(response =>
                    setAllowEmails(response.data.allowEmailNotifications),
                ),
                'Failed to retrieve e-mail setting from server',
            );
    }, [token, setAllowEmails]);

    return (
        <PageLayout paddingTop="2em">
            <Title>Settings</Title>
            <Form.Check
                onChange={updateEmailSetting}
                checked={allowEmails}
                name="allowEmail"
                type="checkbox"
                label="Allow e-mail notifications"
            />
        </PageLayout>
    );
};

const mapStateToProps = (state: RootStore) => ({
    token: selectSessionToken(state),
});

export default connect(mapStateToProps, null)(SettingsPage);
