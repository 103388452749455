import { IVehicle, RootStore } from 'types';
import { VehicleImageMapping } from './vehicle-list.reducer';

export function selectVehicleList(state: RootStore): IVehicle[] {
    return state.vehicleList.vehicles;
}

export function selectSelectedVehicle(state: RootStore): IVehicle | null {
    return state.vehicleList.selectedVehicle;
}

export function selectVehicleListIsLoading(state: RootStore): boolean {
    return state.vehicleList.isFetching;
}

export function selectVehicleListError(state: RootStore): string | undefined {
    return state.vehicleList.error;
}

export function selectVehicleImage(state: RootStore): VehicleImageMapping {
    return state.vehicleList.imagesStore;
}
