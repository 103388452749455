import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { IService, Markets, RootStore, SubscribeActionPayload } from 'types';
import api from 'utils/api/services.api';
import { AnyAction } from 'redux';
import MarketUtils from 'utils/markets.utils';

const loadServiceDetailsActions = {
    next: createActionCreator('FETCH_SERVICE_DETAILS_NEXT'),
    complete: createActionCreator('FETCH_SERVICE_DETAILS_COMPLETE', resolve => (service: IService) => resolve(service)),
    error: createActionCreator('FETCH_SERVICE_DETAILS_ERROR', resolve => (error: string) => resolve(error)),
};

function _loadServiceDetailsThunk(serviceId: number) {
    return async (dispatch: ThunkDispatch<RootStore, {}, AnyAction>, getState: () => RootStore) => {
        dispatch(loadServiceDetails.next());

        const vehicle = getState().vehicleList.selectedVehicle;

        try {
            const service = await api.getServiceDetails(serviceId, vehicle ? vehicle.vin : undefined);
            service.requirements.markets && (service.requirements as Markets).markets.sort();
            service.content.preconditions.markets.sort((a, b) => MarketUtils.helperSortIServiceMarkets(a, b));
            service.content.preconditions.markets.map(item => item.cities.sort());

            dispatch(loadServiceDetails.complete(service));
        } catch (error) {
            dispatch(loadServiceDetails.error(JSON.stringify(error)));
        }
    };
}

export const loadServiceDetails = Object.assign(_loadServiceDetailsThunk, loadServiceDetailsActions);

function _loadPublicServiceDetailsThunk(serviceId: number) {
    return async (dispatch: ThunkDispatch<RootStore, {}, AnyAction>) => {
        dispatch(loadServiceDetails.next());

        try {
            const service = await api.getPublicServicesDetails(serviceId);
            service.requirements.markets && service.requirements.markets.sort();

            dispatch(loadServiceDetails.complete(service));
        } catch (error) {
            dispatch(loadServiceDetails.error(JSON.stringify(error)));
        }
    };
}
export const loadPublicServiceDetails = Object.assign(_loadPublicServiceDetailsThunk, loadServiceDetailsActions);

export const subscribeAction = (serviceId: number, payload: SubscribeActionPayload) => {
    return (dispatch: ThunkDispatch<RootStore, {}, AnyAction>, getState: () => RootStore) => {
        const vehicle = getState().vehicleList.selectedVehicle;
        const vin = vehicle ? vehicle.vin : undefined;

        return api.subscribe(serviceId, vin, payload).finally(() => dispatch(loadServiceDetails(serviceId)));
    };
};

export const unsubscribeAction = (serviceId: number) => {
    return (dispatch: ThunkDispatch<RootStore, {}, AnyAction>, getState: () => RootStore) => {
        const vehicle = getState().vehicleList.selectedVehicle;
        const vin = vehicle ? vehicle.vin : undefined;

        return api.unsubscribe(serviceId, vin).finally(() => dispatch(loadServiceDetails(serviceId)));
    };
};
