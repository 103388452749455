import * as React from 'react';
import { useEffect, useState } from 'react';
import { MarketingEntry } from '../../types/marketing.types';
import api from '../../utils/api/marketing.api';
import styled from 'styled-components';
import MarketingTile from './marketing-tile';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toastifyPromise } from '../../utils/toast-utils';

export const MarketingGrid = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
`;

const MarketingSection: React.FC = () => {
    const [entries, setEntries] = useState([] as MarketingEntry[]);

    useEffect(() => {
        toastifyPromise(api.getMarketing(), 'Failed to load news').then(entries => entries && setEntries(entries));
    }, [setEntries]);

    return (
        <MarketingGrid>
            <Carousel
                style={{ zIndex: 1 }}
                nextIcon={
                    <span
                        aria-hidden="true"
                        style={{ bottom: '5.5%', position: 'absolute' }}
                        className="carousel-control-next-icon"
                    />
                }
                prevIcon={
                    <span
                        aria-hidden="true"
                        style={{ bottom: '5.5%', position: 'absolute' }}
                        className="carousel-control-prev-icon"
                    />
                }
            >
                {entries.length > 0 &&
                    entries.map(entry => (
                        <Carousel.Item key={entry.id}>
                            <Link to={entry.serviceId ? `/services/${entry.serviceId}` : '#'}>
                                <MarketingTile key={entry.id} entry={entry} />
                            </Link>
                        </Carousel.Item>
                    ))}
            </Carousel>
        </MarketingGrid>
    );
};

export default MarketingSection;
