import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { IConversation, IConversationOverview } from 'types';
import api from 'utils/api/messages.api';

function _fetchConversationsThunk() {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        dispatch(fetchConversations.next());

        try {
            const conversations = await api.getConversations();

            dispatch(fetchConversations.complete(conversations));
        } catch (error) {
            dispatch(fetchConversations.error(JSON.stringify(error)));
        }
    };
}

export const fetchConversations = Object.assign(_fetchConversationsThunk, {
    next: createActionCreator('FETCH_CONVERSATIONS_NEXT'),
    complete: createActionCreator('FETCH_CONVERSATIONS_COMPLETE', resolve => (conversations: IConversationOverview[]) =>
        resolve(conversations),
    ),
    error: createActionCreator('FETCH_CONVERSATIONS_ERROR', resolve => (error: string) => resolve(error)),
});

function _fetchMessagesThunk(conversationId: string | undefined) {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        dispatch(fetchMessages.next());

        if (!conversationId) {
            dispatch(
                fetchMessages.complete({
                    id: '',
                    userId: '',
                    userMail: '',
                    messages: [],
                }),
            );
            return;
        }

        try {
            const messages = await api.getMessages(conversationId);

            dispatch(fetchMessages.complete(messages));
        } catch (error) {
            dispatch(fetchMessages.error(JSON.stringify(error)));
        }
    };
}

export const fetchMessages = Object.assign(_fetchMessagesThunk, {
    next: createActionCreator('FETCH_MESSAGES_NEXT'),
    complete: createActionCreator('FETCH_MESSAGES_COMPLETE', resolve => (messages: IConversation) => resolve(messages)),
    error: createActionCreator('FETCH_MESSAGES_ERROR', resolve => (error: string) => resolve(error)),
});
