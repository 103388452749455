import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { LoadServicesPayload, RootStore } from 'types';
import api from 'utils/api/services.api';
import { AnyAction } from 'redux';
import MarketUtils from 'utils/markets.utils';

const createdActions = {
    next: createActionCreator('LOAD_SERVICES_NEXT'),
    complete: createActionCreator('LOAD_SERVICES_COMPLETE', resolve => (servicesAction: LoadServicesPayload) =>
        resolve(servicesAction),
    ),
    error: createActionCreator('LOAD_SERVICES_ERROR', resolve => (error: string) => resolve(error)),
};

function _loadServicesThunk() {
    return async (dispatch: ThunkDispatch<RootStore, {}, AnyAction>, getState: () => RootStore) => {
        dispatch(loadServices.next());

        try {
            const vehicle = getState().vehicleList.selectedVehicle;

            const services = await api.getServices(vehicle ? vehicle.vin : undefined);
            services.map(service =>
                service.markets ? service.markets.sort((a, b) => MarketUtils.helperSortMarkets(a, b)) : service,
            );

            dispatch(loadServices.complete({ receivedAt: Date.now(), services: services }));
        } catch (error) {
            dispatch(loadServices.error(JSON.stringify(error)));
        }
    };
}

export const loadServices = Object.assign(_loadServicesThunk, createdActions);

function _loadPublicServicesThunk() {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        dispatch(loadServices.next());

        try {
            const services = await api.getPublicServices();

            dispatch(loadServices.complete({ receivedAt: Date.now(), services: services }));
        } catch (error) {
            dispatch(loadServices.error(JSON.stringify(error)));
        }
    };
}

export const loadPublicServices = Object.assign(_loadPublicServicesThunk, createdActions);
