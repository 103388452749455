import React, { Fragment } from 'react';
import Button from 'components/button';
import styled from 'styled-components';
import Header from 'components/header/header-page';
import HeroImage from 'assets/images/home/landing_page_header_background3.png';
import SectionImage from 'assets/images/home/landing_page_section_pic2.png';
import SectionBlock from 'components/blocks/showcase-section';
import LegalBlock from 'components/blocks/legal-section';
import SvgImage from '../../assets/icons/symbols.svg';
import { Link } from 'react-router-dom';
import { getOAuthState, getOAuthUrl } from '../../utils/login-utils';
import { RootStore } from 'types';
import { selectSessionIsAuthenticated } from 'redux/session/session.selector';
import { connect } from 'react-redux';

const Container = styled.main`
    max-width: 1024px;
    margin: 0 auto;
`;

interface HomeProps {
    isUserLoggedIn: boolean;
}

function Home({ isUserLoggedIn }: HomeProps) {
    const onLogin = async () => {
        window.location.replace(await getOAuthUrl(getOAuthState()));
    };

    return (
        <Fragment>
            <Header image={HeroImage} title="SHAPE THE FUTURE OF MOBILITY WITH US" isLarge></Header>

            <Container>
                <SectionBlock svg={SvgImage} greyBackground swap>
                    <h3>What is BMW Group Labs?</h3>
                    <p>
                        It’s a program where you can be among the first users to test BMW Group’s latest mobility
                        services. As a test user you get an access to the services others don’t. We develop our services
                        based on your feedback. Let’s shape the future of mobility together.
                    </p>
                    <Link to={isUserLoggedIn ? '/services' : '/services-overview'}>
                        <Button>Show Services</Button>
                    </Link>
                </SectionBlock>

                <SectionBlock contentUrl={SectionImage} contentType={'IMAGE'} greyBackground>
                    <h3>Want to join?</h3>

                    <p>
                        You can register to try out our new services if you own a BMW or MINI and fulfill other criteria
                        for the individual service you want to test. See the current services below and click to read
                        more on their individual pages.
                    </p>

                    {isUserLoggedIn && (
                        <Link to="/services">
                            <Button>Show services</Button>
                        </Link>
                    )}
                    {!isUserLoggedIn && <Button onClick={onLogin}>Login</Button>}
                </SectionBlock>

                <LegalBlock title="DISCLAIMER">
                    We want you to know that the services offered via BMW Group Labs are not commercialized yet,
                    guaranteed, or officially supported. They are under active development and benefit from your
                    feedback. They may occasionally be interrupted or disappear temporarily or permanently. Also, there
                    may be restriction in certain countries, limited number of users and a certain vehicle
                    type/technology necessary to use the service.
                </LegalBlock>
            </Container>
            <div style={{ padding: '20px' }}></div>
        </Fragment>
    );
}

const mapStateToProps = (state: RootStore) => ({
    isUserLoggedIn: selectSessionIsAuthenticated(state),
});

export default connect(mapStateToProps)(Home);
