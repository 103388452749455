import React from 'react';
import styled from 'styled-components';
import { Colors, Typography, Spacing } from 'config/styling.constants';

export interface SectionBlockProps {
    readonly title?: string;
}

const Section = styled.section`
    max-width: 1024px;
    margin-top: 0;
    background-color: ${Colors.lightGrey};
`;

const BlockTitle = styled.h2`
    color: inherit;
    text-align: inherit;
    padding: 0;
    background-image: none;
    font-weight: bold;
    font-size: ${Typography.smallTextFontSize};
    line-height: ${Typography.smallTextLineHeight};
    letter-spacing: ${Typography.smallTextLetterSpacing};
`;

const BlockText = styled.div`
    font-size: ${Typography.smallTextFontSize};
    line-height: ${Typography.smallTextLineHeight};
    letter-spacing: ${Typography.smallTextLetterSpacing};

    max-width: 80ch;
`;

const LegalBlock = styled.div`
    padding: ${Spacing.lg};
`;

const LegalSection: React.FC<SectionBlockProps> = ({ title, children }) => {
    return (
        <Section>
            <LegalBlock>
                <BlockTitle>{title}</BlockTitle>
                <BlockText>{children}</BlockText>
            </LegalBlock>
        </Section>
    );
};

export default LegalSection;
