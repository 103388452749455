import { IConversationOverview, IMessage, RootStore } from '../../types';

export function selectMailboxConversations(state: RootStore): IConversationOverview[] {
    return state.mailbox.conversations;
}

export function selectMailboxConversationSlice(state: RootStore, count: number): IConversationOverview[] {
    return state.mailbox.conversations.slice(0, count);
}

export function selectMailboxIsFetching(state: RootStore): boolean {
    return state.mailbox.isFetching;
}

export function selectMailboxError(state: RootStore): string {
    return state.mailbox.error;
}

export function selectMailboxMessages(state: RootStore): IMessage[] {
    return state.mailbox.conversationMessages.messages;
}

export function selectMessagesUserId(state: RootStore): string {
    return state.mailbox.conversationMessages.userId;
}
