import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { Colors, Spacing } from 'config/styling.constants';

const MessageContainer = styled.div`
    background-color: ${Colors.white};
    padding: ${Spacing.sm};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: ${Spacing.xs};
    display: flex;
    flex-direction: row;
    cursor: pointer;
`;

const Item = styled.div`
    margin: auto 0;
    padding: 0 ${Spacing.sm};
    flex: 1;
`;

const DashboardLoadingMessage: React.FC = () => {
    return (
        <MessageContainer>
            <Item>
                <Skeleton />
            </Item>
            <Item>
                <Skeleton count={2} />
            </Item>
            <Item>
                <Skeleton />
            </Item>
        </MessageContainer>
    );
};

export default DashboardLoadingMessage;
