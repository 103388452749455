import Client from 'utils/api/api-client';
import { AxiosInstance, AxiosResponse } from 'axios';
import { IUser } from '../../types';
import Config from 'config/config';

export class UserAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    async getProfile(token: string, acceptedTerms: boolean) {
        // Add token manually because interceptor works only after this request

        try {
            const response = await fetch(`${Config.BASE_URL}/auth/user`, {
                // eslint-disable-next-line
                //@ts-ignore
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Accept-Terms': acceptedTerms,
                },
            });

            return {
                data: response.status === 200 ? await response.json() : '',
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                config: {},
            } as AxiosResponse;
        } catch (err) {
            throw err;
        }
    }

    updateEmailSetting(allowEmailNotifications: boolean): Promise<IUser> {
        return this.client
            .put('/settings/email', {
                allowEmailNotifications,
            })
            .then(resp => (resp.status === 200 ? resp.data : Promise.reject(resp.statusText)));
    }
}
export default new UserAPI(Client);
