import React from 'react';
import styled from 'styled-components';
import PageLayout from 'components/page.layout';
import { IConversationOverview, IMessage, IPostMessage } from 'types/message.types';
import ConversationBox from 'pages/mailbox/conversation-box';
import MessageBox from './message-box';
import MessageAPI from 'utils/api/messages.api';
import { RootStore } from 'types';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
    selectMailboxConversations,
    selectMailboxMessages,
    selectMessagesUserId,
} from 'redux/mailbox/mailbox.selector';
import { fetchConversations, fetchMessages } from 'redux/mailbox/mailbox.actions';
import { Title } from 'components/styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction } from 'deox';
import { findIndex } from 'lodash';
import { Spacing } from 'config/styling.constants';
import { toastifyPromise } from '../../utils/toast-utils';

const Row = styled.div`
    display: flex;
    margin-top: ${Spacing.xs};
`;

const Left = styled.div`
    flex: 1;
`;

const Right = styled.div`
    flex: 2;
    padding-left: 15px;
`;

interface MailboxState {
    selectedIndex: number;
    selectedConversationId?: string;
    windowWidth: number;
}

interface MailboxProps extends RouteComponentProps {
    conversations: IConversationOverview[];
    conversationMessages: IMessage[];
    fetchConversations: () => void;
    fetchMessages: (conversationId: string | undefined) => void;
}

class Mailbox extends React.Component<MailboxProps, MailboxState> {
    constructor(props: MailboxProps) {
        super(props);
        this.state = {
            selectedIndex: 0,
            selectedConversationId: this.getQueryParameter('conversationId'),
            windowWidth: window.innerWidth,
        };

        this.selectConversationByIndex = this.selectConversationByIndex.bind(this);
        this.loadMessages = this.loadMessages.bind(this);
        this.getQueryParameter = this.getQueryParameter.bind(this);
        this.getIndexByConversation = this.getIndexByConversation.bind(this);
        this.getConversation = this.getConversation.bind(this);
        this.onSendMessage = this.onSendMessage.bind(this);
        this.updateWindowWidth = this.updateWindowWidth.bind(this);
    }

    componentDidMount() {
        this.props.fetchConversations();
        window.addEventListener('resize', this.updateWindowWidth);
    }

    componentDidUpdate(prevProps: MailboxProps) {
        if (this.props.conversations !== prevProps.conversations) {
            this.loadMessages();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }

    updateWindowWidth() {
        this.setState({ windowWidth: window.innerWidth });
    }

    getQueryParameter(name: string): string | undefined {
        const value = new URLSearchParams(this.props.location.search).get(name);
        return value ? value : undefined;
    }

    loadMessages() {
        const conversationId = this.state.selectedConversationId;

        if (conversationId) {
            this.props.fetchMessages(conversationId);
            const i = this.getIndexByConversation(conversationId);
            this.selectConversationByIndex(i);
        } else {
            this.selectConversationByIndex(0);
        }
    }

    selectConversationByIndex(conversationIndex: number) {
        const conversation: IConversationOverview | undefined = this.getConversation(conversationIndex);
        if (conversation) {
            // Set state
            this.setState({
                selectedIndex: conversationIndex,
                selectedConversationId: conversation.id,
            });

            // Load messages for selected conversation
            this.props.fetchMessages(conversation.id);
        }
    }

    getIndexByConversation(conversationId: string): number {
        const index = findIndex(this.props.conversations, c => c.id === conversationId);
        return index === -1 ? 0 : index;
    }

    getConversation(conversationIndex: number): IConversationOverview | undefined {
        if (this.props.conversations.length > conversationIndex) {
            return this.props.conversations[conversationIndex];
        } else {
            return undefined;
        }
    }

    onSendMessage(messageBody: string) {
        const conversation = this.getConversation(this.state.selectedIndex);
        if (!conversation) {
            return;
        }
        const message: IPostMessage = {
            message: messageBody,
        };
        toastifyPromise(MessageAPI.postMessage(conversation.serviceId, message), 'Failed to send message').then(() => {
            // Reload conversations to reflect changes in order by latest message
            // Messages will be reloaded by componentDidUpdate
            this.props.fetchConversations();
        });
    }

    render() {
        return (
            <PageLayout paddingTop="2em">
                <Title marginBottom={Spacing.sm} id="mailbox">
                    Mailbox
                </Title>
                {this.state.windowWidth > 600 && (
                    <Row>
                        <Left>
                            <ConversationBox
                                conversations={this.props.conversations}
                                selectedIndex={this.state.selectedIndex}
                                selectConversation={i => this.selectConversationByIndex(i)}
                            />
                        </Left>
                        <Right>
                            <MessageBox messages={this.props.conversationMessages} onSendMessage={this.onSendMessage} />
                        </Right>
                    </Row>
                )}
                {this.state.windowWidth <= 600 && (
                    <div>
                        <ConversationBox
                            conversations={this.props.conversations}
                            selectedIndex={this.state.selectedIndex}
                            selectConversation={i => this.selectConversationByIndex(i)}
                        />
                        <hr />
                        <h5 style={{ marginBottom: '20px' }}>
                            {this.props.conversations.length > 0
                                ? this.props.conversations[this.state.selectedIndex].serviceName || 'General feedback'
                                : ''}
                        </h5>
                        <MessageBox messages={this.props.conversationMessages} onSendMessage={this.onSendMessage} />
                    </div>
                )}
            </PageLayout>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    conversations: selectMailboxConversations(state),
    conversationMessages: selectMailboxMessages(state),
    channelId: selectMessagesUserId(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => ({
    fetchConversations: () => dispatch(fetchConversations()),
    fetchMessages: (conversationId: string | undefined) => dispatch(fetchMessages(conversationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mailbox);
