import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import api from 'utils/api/configuration.api';
import { IConfiguration } from 'types';
import MarketsUtils from 'utils/markets.utils';

function _fetchConfigurationThunk() {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        dispatch(fetchConfiguration.next());

        try {
            const response = await api.getConfig();

            if (response.status === 200) {
                MarketsUtils.setMarkets(response.data.markets);
                dispatch(fetchConfiguration.complete(response.data));
            } else {
                dispatch(fetchConfiguration.error(JSON.stringify(response.data)));
            }
        } catch (error) {
            dispatch(fetchConfiguration.error('Error: ' + JSON.stringify(error)));
        }
    };
}

export const fetchConfiguration = Object.assign(_fetchConfigurationThunk, {
    next: createActionCreator('FETCH_CONFIGURATION_NEXT'),
    complete: createActionCreator('FETCH_CONFIGURATION_COMPLETE', resolve => (config: IConfiguration) =>
        resolve(config),
    ),
    error: createActionCreator('FETCH_CONFIGURATION_ERROR', resolve => (error: string) => resolve(error)),
});

export const enableTour = {
    toggle: createActionCreator('GUIDED_TOUR_TOGGLE'),
    handleChange: createActionCreator('GUIDED_TOUR_CHANGE', resolve => (showTour: boolean) => resolve(showTour)),
};
