import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import PageLayout from 'components/page.layout';
import ServiceHeader from 'components/header/header-service';
import RequirementsSection from 'components/blocks/requirements-section';
import ApplySection from 'components/blocks/apply-section';
import ServiceUtils from 'utils/service.utils';
import { IService, RootStore } from 'types';
import {
    selectServicesDetails,
    selectServicesDetailsIsFetchingState,
} from 'redux/serviceDetails/service-details.selector';
import { loadPublicServiceDetails } from 'redux/serviceDetails/service-details.actions';
import Button from 'components/button';
import SectionImage from 'assets/images/services/BMW_building.jpg';
import { REDIRECT_URI } from 'config/constants';
import { ServiceContentSections } from 'components/blocks/content-sections-section';

type TParams = { id: string };

interface ServiceDetailsProps extends RouteComponentProps<TParams> {
    selectedService?: IService;
    isLoading: boolean;
    loadServiceDetails: (serviceId: number) => void;
}

interface ServiceDetailsState {
    serviceId: number;
}

class ServiceDetails extends React.Component<ServiceDetailsProps, ServiceDetailsState> {
    constructor(props: ServiceDetailsProps) {
        super(props);
        const serviceId = Number(this.props.match.params.id);

        this.state = { serviceId };
    }

    componentDidMount() {
        this.props.loadServiceDetails(this.state.serviceId);
    }

    render() {
        const service = this.props.selectedService || ServiceUtils.createEmptyService();

        return (
            <Fragment>
                <ServiceHeader
                    image={service.content.headerImage}
                    title={service.content.headline}
                    subtitle={service.content.subline}
                    isLarge
                >
                    {ServiceUtils.isServiceActive(service) && (
                        <Button outline to={`/services/${service.id}`} style={{ width: '40%' }}>
                            Register Now
                        </Button>
                    )}
                </ServiceHeader>

                <PageLayout>
                    <ServiceContentSections
                        sections={service.content.sections}
                        isServiceSubscribed={service.subscribed}
                        selectedVehicle={null}
                    />

                    <RequirementsSection
                        title="TEST USER REQUIREMENTS"
                        preconditions={service.content.preconditions}
                        serviceEmail={service.serviceEmail}
                        serviceName={service.name}
                    />
                    {ServiceUtils.isServiceActive(service) && (
                        <ApplySection title="Register for this service" serviceId={service.id}>
                            <p>
                                Click on “Register Now” to participate in the Service and be part of the BMW Group Labs
                                family. Use your Connected Drive Account for the registration and simply choose your
                                mapped vehicles.
                            </p>
                        </ApplySection>
                    )}
                    <PageLayout title="Legal Documents" headerImage={SectionImage}>
                        <h3>Terms & Conditions and Data Privacy of {service.name}</h3>
                        <br />
                        <a href={`${REDIRECT_URI}/services/${service.id}/terms`}>Terms & Conditions</a>
                        <br />
                        <a href={`${REDIRECT_URI}/services/${service.id}/privacy`}>Data Privacy Statement</a>
                        <br />
                    </PageLayout>
                </PageLayout>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    selectedService: selectServicesDetails(state),
    isLoading: selectServicesDetailsIsFetchingState(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadServiceDetails: (serviceId: number) => dispatch(loadPublicServiceDetails(serviceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
