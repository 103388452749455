import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { IConversationOverview, RootStore } from 'types';
import { ThunkDispatch } from 'redux-thunk';
import { selectMailboxConversationSlice, selectMailboxIsFetching } from 'redux/mailbox/mailbox.selector';
import { fetchConversations } from 'redux/mailbox/mailbox.actions';
import DashboardMessageItem from 'components/mailbox/dashboard-message-item';
import DashboardLoadingMessage from 'components/mailbox/dashboard-loading-message';
import { Title } from 'components/styled-components';
import { Spacing } from 'config/styling.constants';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const MESSAGES_COUNT = 3;

interface DashboardMessagesGridProps extends RouteComponentProps {
    loading: boolean;
    conversations: IConversationOverview[];
    fetchConversations: () => void;
}

const buildRoute = (conversationId: string) => `/mailbox?conversationId=${conversationId}`;

class DashboardMessagesGrid extends React.Component<DashboardMessagesGridProps> {
    constructor(props: any) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        this.props.fetchConversations();
    }

    onClick(conversation: IConversationOverview) {
        this.props.history.push(buildRoute(conversation.id));
    }

    render() {
        const { loading, conversations } = this.props;
        const unreadMessagesCount = conversations.filter(msg => msg.hasUnreadMessages).length;
        const messageCountString = unreadMessagesCount > 0 ? ` (${unreadMessagesCount})` : '';

        return (
            <Fragment>
                <Title marginBottom={Spacing.sm}>Mailbox{messageCountString}</Title>

                {loading && conversations.length === 0 && <DashboardLoadingMessage />}

                {!loading && conversations.length === 0 && (
                    <div>
                        <p>No messages available</p>
                    </div>
                )}

                {conversations.map((conversation, index) => (
                    <DashboardMessageItem
                        key={index}
                        messageItem={conversation}
                        onClick={() => this.onClick(conversation)}
                    />
                ))}
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    loading: selectMailboxIsFetching(state),
    conversations: selectMailboxConversationSlice(state, MESSAGES_COUNT),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    fetchConversations: () => dispatch(fetchConversations()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardMessagesGrid));
