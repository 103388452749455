import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { RootStore } from './types';
import { selectSessionExpiry } from './redux/session/session.selector';
import { connect } from 'react-redux';
import { forwardToLogin, getOAuthState } from './utils/login-utils';

interface LogoutWarningProps {
    expiryDate?: Date | null;
}

const LogoutWarning: React.FC<LogoutWarningProps> = ({ expiryDate }) => {
    const [show, setShow] = useState(false);

    const closeWarningAndLogin = useCallback(() => {
        setShow(false);
        forwardToLogin(getOAuthState());
    }, [setShow]);

    const showWarning = useCallback(() => setShow(true), [setShow]);
    const hideWarning = useCallback(() => setShow(false), [setShow]);

    // Display a warning as soon as the token is expired
    useEffect(() => {
        if (!expiryDate) {
            return;
        }
        const timeout = expiryDate.getTime() - new Date().getTime();

        if (timeout > 0) {
            const timerId = setTimeout(() => {
                showWarning();
            }, timeout);
            return () => clearTimeout(timerId);
        }
    }, [expiryDate, showWarning]);

    return (
        <>
            <Modal show={show} onHide={hideWarning}>
                <Modal.Header closeButton translate>
                    <Modal.Title>Session expired</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You are not logged in anymore.</p>
                    <p>Close this message and save your work or login again (any unsaved changes will be lost).</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeWarningAndLogin}>
                        Login again
                    </Button>
                    <Button variant="secondary" onClick={hideWarning}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: RootStore) => ({
    expiryDate: selectSessionExpiry(state),
});

export default connect(mapStateToProps, null)(LogoutWarning);
