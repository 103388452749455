import { IServiceOverview, RootStore } from 'types';

export function selectServicesIsFetchingState(state: RootStore): boolean {
    return state.servicesOverview.isFetching;
}

export function selectServicesErrorState(state: RootStore): string {
    return state.servicesOverview.error;
}

export function selectServicesItems(state: RootStore): IServiceOverview[] {
    return state.servicesOverview.services || [];
}

export function selectSubscribedServices(state: RootStore): IServiceOverview[] {
    return selectServicesItems(state)
        .filter((value: IServiceOverview) => value.subscribed === true)
        .slice(0, 2);
}
