import React from 'react';
import styled, { css } from 'styled-components';
import { Spacing, Watermark } from 'config/styling.constants';

export interface WatermarkWrapperProps {
    readonly watermark: 'white' | 'light';
    readonly position: 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right';
    readonly padding: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const WatermarkWrapper = styled.div<WatermarkWrapperProps>`
	z-index: 0;

	background-repeat: no-repeat;
	background-size: 266px 266px;
	${props =>
        props.watermark &&
        css`
		padding: ${(props: WatermarkWrapperProps) =>
            (props.padding === 'xxs' && `${Spacing.xxs}`) ||
            (props.padding === 'xs' && `${Spacing.xs}`) ||
            (props.padding === 'sm' && `${Spacing.sm}`) ||
            (props.padding === 'md' && `${Spacing.md}`) ||
            (props.padding === 'lg' && `${Spacing.lg}`) ||
            (props.padding === 'xl' && `${Spacing.xl}`)};
			;
		background-image: url("${(props: WatermarkWrapperProps) =>
            (props.watermark === 'white' && `${Watermark.white}`) ||
            (props.watermark === 'light' && `${Watermark.light}`)}");
	`} background-position: ${(props: WatermarkWrapperProps) =>
    (props.position === 'top-right' && 'top -66.5px right -66.5px;') ||
    (props.position === 'top-left' && 'top -66.5px left -66.5px;') ||
    (props.position === 'bottom-left' && 'bottom -66.5px left -66.5px;') ||
    (props.position === 'bottom-right' && 'bottom -66.5px right -66.5px;')};
`;

const WatermarkBlock: React.FC<WatermarkWrapperProps> = ({ watermark, position, padding, children }) => {
    return (
        <WatermarkWrapper watermark={watermark} position={position} padding={padding}>
            {children}
        </WatermarkWrapper>
    );
};
export default WatermarkBlock;
